import React from 'react'

const ErrorRelod = ({minHeight,onclick}) => {
    return (
        <div className=' d-flex justify-content-center align-items-center w-100' style={{minHeight:minHeight?minHeight:"20rem"}}>
            <div className=' text-center'>
                <i className=' fa fa-exclamation-circle text-danger fs-2 mb-4'></i>
                <span className=' d-block'>Une érreur c'est produite, cliquez sur le bouton ci-dessous pour rééssayer</span>
                <div onClick={()=>onclick()} className=' border-1 border-black rounded-circle d-flex justify-content-center align-items-center bg-body-secondary mx-auto mt-4' role='button' style={{width:"5rem",height:"5rem"}}>
                    <i className=' fa fa-arrows-rotate fa-3x'></i>
                </div>
            </div>
        </div>
    )
}

export default ErrorRelod