import React from 'react'

const ProductCardPlaceholder = () => {
    return (
        <div className="product-speeddial placeholder-glow">
                <div className=' p-2 large-bizness-card rounded-3 mx-2 shadow-sm border' style={{width:'14rem'}} role='button'>
                    <div className=' d-flex justify-content-center align-items-center rounded-3 overflow-hidden bg-body-secondary' style={{height:"20rem"}}>
                        <img alt='' className=' object-fit-cover w-100 h-100 placeholder col-12' />
                    </div>
                    <span className=' mt-2 fs-6 fw-bold offer-card-description placeholder col-12'></span>
                    <span className=' mt-2 fs-6 fw-bold offer-card-description placeholder col-8'></span>
                </div>
            </div>
    )
}

export default ProductCardPlaceholder
