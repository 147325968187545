import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import biz from "../../api/axiosConfig";
import { likeBussinessUrl } from "../../api/enpoint";


//Action
export const likeBussiness = createAsyncThunk('likeBussiness', async (id)=>{
    const resp = await biz.post(likeBussinessUrl+id)
    return resp.data;
})


const likeBussinessSlice = createSlice({
    name:'likeBussiness',
    initialState:{
        loadLikeB: false,
        likeB: null,
        errLikeB: null,
    },
    extraReducers: (builder) =>{
        builder
        .addCase(likeBussiness.pending, (state, action) =>{
            state.loadLikeB = true
            state.likeB = null
            state.errLikeB = null
        })
        .addCase(likeBussiness.fulfilled, (state, action) =>{
            state.loadLikeB = false
            state.likeB = action.payload
            state.errLikeB = null
        })
        .addCase(likeBussiness.rejected, (state, action) =>{
            state.loadLikeB = false
            if (action.error.message === "Request failed with status code 401") {
                state.errLikeB = 'Access Denied'
            } else {
                state.errLikeB = action.error.message
            }
        })
    }
})

export default likeBussinessSlice.reducer;