/* eslint-disable eqeqeq */
import React, { useRef, useState } from 'react'
import IconText from './IconText'
import { NavLink } from 'react-router-dom'
import { Tooltip } from 'primereact/tooltip'
import Modal from 'react-responsive-modal'
import { toast } from 'react-toastify'
import biz from '../api/axiosConfig'
import { useDispatch, useSelector } from 'react-redux'
import { fetchBussinesses } from '../redux/slices/bussinessGetAll'
import { deleteBussinessUrl } from '../api/enpoint'

const LargeBiznessCard = ({logo, cover, name, domain, location, rating, bizId, creatorId}) => {

    const dispatch = useDispatch()

    const { user } = useSelector(state => state.user)

    const[del, setDel] = useState(false)
    
    const toastload = useRef(null)
    const handleDel = () =>{
        toastload.current = toast.loading("Veuillez patienter...",{position: toast.POSITION?.TOP_LEFT})

        biz.put(deleteBussinessUrl+bizId,)
        .then(res =>{
            toast.update(toastload.current, {render: <span>Suppression éffectuée</span>,type: "success",isLoading: false, hideProgressBar: false})
            dispatch(fetchBussinesses())
        })
        .catch(err =>{
            toast.update(toastload.current, {render: "Désolé, Une erreur c'est produite", type: "error", isLoading: false, hideProgressBar: false, closeOnClick: true})
            console.log('err',err.response.data)
        })
    }

    return (
        <div className='position-relative'>
            <NavLink to={`/bizness/${bizId}`}>
                <div className=' p-2 m-2 rounded-3 large-bizness-card border'>
                    <div className=' w-100 rounded-3 d-flex justify-content-center align-items-center bg-body-secondary overflow-hidden' style={{height: "16rem"}}>
                        <img alt='hello' src={cover} className=' object-fit-cover h-100 w-100' />
                    </div>

                    <div className=' d-flex mt-2 align-items-center'>
                        <div className=' rounded-3 d-flex justify-content-center align-items-center bg-body-secondary overflow-hidden border bordercolor-primary shadow' style={{height: "5rem", width:'5rem'}}>
                            <img alt='hello' src={logo} className=' object-fit-cover h-100 w-100' />
                        </div>
                        <div className=' ms-2 text-black'>
                            <span className=' d-block fs-5 fw-bold color-secondary mb-0 lh-sm'>{name}</span>
                            <span className='d-block color-primary fst-italic'>{domain}</span>
                            <span className='d-flex'><IconText iconClass=" fa fa-location-dot text-black text-opacity-75" text={location} /></span>
                            <span> <i className=' fa fa-star text-warning'></i> {rating} </span>
                        </div>
                    </div>
                </div>
            </NavLink>
            {
                (user?.id == creatorId) &&
                <>
                    <Tooltip target=".del-btn" position="left" />
                    <div className='position-absolute top-0 end-0 mt-3 me-3' role='button'>
                        <div data-pr-tooltip="Supprimer" className='del-btn p-3  bg-danger rounded-circle d-flex justify-content-center align-items-center' onClick={()=>setDel(true)} style={{width:"3rem", height:"3rem"}}>
                            <i className='fa fa-trash-can fs-4 text-white'></i>
                        </div>
                    </div>
                </>
            }



            <Modal center={true} open={del} onClose={()=>setDel(false)} >
                <div className='p-md-2 p-1 text-start'>
                    <h4 className='fw-bold pe-md-3 fs-4'>Supprimer le Biz'ness {name} </h4>
                    <div className=' d-flex justify-content-start align-items-center mt-2'>
                        <i className=' fa fa-triangle-exclamation text-danger fa-3x'></i>
                        <p className=' ms-2 mb-0'>Êtes-vous sûr de vouloir supprimer cette offre ?</p>
                    </div>
                    <div className=' d-flex justify-content-end align-items-center'>
                        <button onClick={()=>setDel(false)} className=' btn border py-1 bordercolor-primary'>Annuler</button>
                        <button onClick={()=>handleDel()} className=' border-0 py-1 px-2 none bgcolor-primary rounded text-white ms-1'>Confirmer</button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default LargeBiznessCard
