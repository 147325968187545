import React from 'react'
import Modal from 'react-responsive-modal'
import { NavLink } from 'react-router-dom'

const LoginModal = ({open, onClose}) => {
    return (
        <div>
            <Modal open={open} center={true} onClose={()=>onClose()} >
                <div className=' px-3 py-4 text-center' style={{maxWidth:'500px'}}>
                    <h3>Fonctionnalitée indisponible</h3>
                    <span className=' fs-6 fst-italic mt-3 d-block'>Veuillez vous connecter à votre compte pour jouir de toutes les fonctionnalitées de notre plateforme</span>
                    <div className=' row justify-content-center align-items-center mt-4'>
                        <div className=' col-auto'>
                            <NavLink to={'/login'} className=' bgcolor-primary px-3 py-2 text-white '>
                                Se connecter
                            </NavLink>
                        </div>
                        <div className=' col-auto'>
                            <NavLink to={'/'} className=' bgcolor-primary px-3 py-2 text-white '>
                                Accueil
                            </NavLink>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default LoginModal