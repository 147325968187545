import axios from "axios";
import { decryptData } from "./encryptData";
import Cookies from "js-cookie";
import { baseURL } from "./enpoint";

const biz = axios.create({
    baseURL: baseURL
})

biz.interceptors.request.use(function (config) {
    let token = undefined;

    if (typeof window !== "undefined") {
        token = Cookies.get('token')?decryptData('token'): undefined;
    }
    if (token) config.headers.authorization = `Bearer ${token}`;
    config.headers["Accept"] = '*/*'
    return config;
});



export default biz;