import React, { useEffect, useState } from 'react'
import { icon_black, profile } from '../constants/files'
import CustomButtons from './CustomButtons'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUser } from '../redux/slices/userGet'
import { baseURL } from '../api/enpoint'
import { NavLink } from 'react-router-dom'
import Cookies from 'js-cookie'



const Header = () => {

    const dispatch = useDispatch()
    const { user, loadUser } = useSelector(state => state.user)

    useEffect(()=>{
        dispatch(fetchUser)
    },[dispatch])

    const [bg, setBg] = useState(false)

    window.addEventListener("scroll",()=>{
        if (window.scrollY > 30) {
            setBg(true)
        } else {
            setBg(false)
        }
    })

    return (
        <header id='navbar' className={'bg-transparent ' + (bg?'fixed-top header-bg top-0 pb-2':'')}>
            <div className='container'>
                <div className='row align-items-center justify-content-between py-1'>
                    <NavLink to={''} className=' col-auto d-flex justify-content-center align-items-center' style={{"height":"2rem"}}>
                        <img src={icon_black} alt='BIZ_CONNECT' className=' img-fluid object-fit-contain h-100' />
                        <span className=' fw-bold color-secondary ms-2 fs-2 d-lg-block d-none'>BIZCONNECT</span>
                    </NavLink>
                    <div className=' col'>
                        <div className=' border bordercolor-primary bg-white rounded-2 shadow-sm d-flex align-items-center justify-content-center overflow-hidden'>
                            <input type='text' className=' border-0 w-100 form-control fs-6 py-0 fst-italic'  placeholder='Rechercher...'/>
                            <span role='button' className=' ms-auto bg-secondary-subtle py-1 px-2'><i className=' fa fa-magnifying-glass color-primary fs-6'></i></span>
                        </div>
                    </div>
                    <div className='col-auto ms-auto d-flex justify-content-end'>
                        {
                            user ?
                            <button type="button" className="btn bg-transparent border-0 dropdown-toggle hover d-flex align-items-center" data-bs-toggle="dropdown" aria-expanded="false">
                                <div className=' rounded-circle overflow-hidden d-flex justify-content-center align-items-center' style={{width:'2.4rem',height:'2.4rem'}}>
                                    <img alt={user.l_name} src={ user.photo?(baseURL + user.photo):profile} className=' object-fit-cover w-100 h-100' />
                                </div>
                                <span className=' w-auto ms-2 fw-bold color-secondary fs-6 text-nowrap overflow-hidden d-none d-md-block' style={{width:'7rem',textOverflow:'ellipsis'}}> {user.f_name} </span>
                            </button>
                            :
                            loadUser?
                                <div className=' placeholder-glow rounded-3 overflow-hidden' style={{width:'6rem'}}>
                                    <div className=' placeholder col-12 py-4'></div>
                                </div>
                            :<CustomButtons text="Se connecter" icon_left=" fa fa-user" link="login" />
                        }
                        <ul className="dropdown-menu overflow-hidden">
                            <li>
                                <NavLink className="dropdown-item row justify-content-center align-items-center" to="/account/profile">
                                    <i className="ri-user-line fs-5 col-auto"></i>
                                    <span className='fs-6 col-auto'>Votre compte</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="dropdown-item row justify-content-center align-items-center" to="/account/bizness">
                                    <i className="ri-store-2-line fs-5 col-auto"></i>
                                    <span className='fs-6 col-auto'>Vos biz'ness</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="dropdown-item row justify-content-center align-items-center" to="/account/favorites">
                                    <i className="ri-heart-line fs-5 col-auto"></i>
                                    <span className='fs-6 col-auto'>Vos favories</span>
                                </NavLink>
                            </li>
                            <li><hr className="dropdown-divider" /></li>
                            <li>
                            {
                                loadUser?
                                <div className=' placeholder-glow'>
                                    <div className=' placeholder col-12 '>kdkkkdkdk</div>
                                </div>:
                                !user ?
                                    <NavLink className="dropdown-item d-flex justify-content-center align-items-center" to="/login">
                                        <button className='btn btn-success'>Se connecter</button>
                                    </NavLink>
                                :<div className="dropdown-item  justify-content-center align-items-center" onClick={()=>{Cookies.remove('token');dispatch(fetchUser())}}>
                                    <CustomButtons reload={true} text="Déconnection" icon_left=" fa fa-right-from-bracket" />
                                </div>
                            }
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header
