import React from 'react'

const LargeBiznessCardPlaceholder = () => {
    return (
        <div className=' p-3 rounded-3 large-bizness-card placeholder-glow'>
            <div className=' w-100 rounded-3 d-flex justify-content-center align-items-center bg-body-secondary overflow-hidden' style={{height: "16rem"}}>
                <img alt='' className=' object-fit-cover h-100 w-100 placeholder col-12' />
            </div>

            <div className=' d-flex mt-4 align-items-center w-100'>
                <div className=' rounded-3 d-flex justify-content-center align-items-center bg-body-secondary overflow-hidden border bordercolor-primary shadow' style={{height: "5rem", width:'5rem'}}>
                    <img alt='' className=' object-fit-cover h-100 w-100 placeholder col-12' />
                </div>
                <div className=' ms-2 w-100'>
                    <span className=' d-block fs-5 fw-bold color-secondary mb-0 lh-sm placeholder col-11'></span>
                    <span className='d-block color-primary fst-italic mt-2 placeholder col-9'></span>
                    <span> <i className=' fa fa-star text-black-50 mt-3'></i></span>
                </div>
            </div>
        </div>
    )
}

export default LargeBiznessCardPlaceholder
