import React, { useRef, useState } from 'react'
import { logo_black } from '../constants/files'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import biz from '../api/axiosConfig'
import { registerUserUrl } from '../api/enpoint'
import { encryptData } from '../api/encryptData'
import { fetchUser } from '../redux/slices/userGet'
import { PhoneInput } from 'react-international-phone'
// import Map from '../components/Map'

const Register = ({setRegister}) => {


    const dispatch = useDispatch()
    
    const[f_name, setFname] = useState('')
    const[l_name, setLname] = useState('')
    const[email, setEmail] = useState('')
    const[phone, setPhone] = useState('')
    const[description, setDescription] = useState('')
    const[photo, setPhoto] = useState()
    const[date_naiss, setDateNaiss] = useState('')
    const[location, setLocation] = useState('Douala')
    const[mdp, setMdp] = useState('')

    
    const navigate = useNavigate()




    const toastload = useRef(null)
    const handleLog = (e) =>{
        e.preventDefault()
        if(f_name && l_name && email && phone && mdp && location){
            toastload.current = toast.loading("Veuillez patienter...",{position: toast.POSITION?.TOP_LEFT})
            const formData = new FormData()

            formData.append('f_name', f_name)
            formData.append('l_name', l_name)
            formData.append('email', email)
            formData.append('telephone', phone)
            formData.append('date_naisss', date_naiss)
            formData.append('description', description)
            formData.append('location', location)
            formData.append('photo', photo)
            formData.append('password', mdp)

            biz.post(registerUserUrl, formData)
            .then(res =>{
                toast.update(toastload.current, {render: <span>Vous êtes connectés<br/> Bienvenu {res.data.name}</span>,type: "success",isLoading: false, hideProgressBar: false})
                encryptData('token',res.data.token)
                dispatch(fetchUser())
                // window.location.reload()
                navigate('/')
            })
            .catch(err =>{
                toast.update(toastload.current, {render: err.response.data.email ?"email déjà existant ! Se connecter ?":err.response.data.pass?"Mot de passe incorrect":"Une erreur c'est produite", type: "error", isLoading: false, hideProgressBar: false, closeOnClick: true})
                console.log('err',err.response.data)
            })
        }else{
            toastload.current = toast.error("Remplir tout les champs", {position: toast.POSITION?.TOP_LEFT, isLoading: false, autoClose: false,hideProgressBar: false, closeOnClick: true})
        }
    }


    return (
        <div className=' login d-flex justify-content-center align-items-center'>
            <div className=' bg-white px-3 py-4 text-center w-100 my-3' style={{maxWidth:'900px'}}>
                <div className=' mx-auto' style={{maxWidth:"600px"}}>
                    <img alt='bizconnect' src={logo_black} className=' img-fluid' style={{height:"110px"}}/>
                    <h2 className=' fw-bold mt-3 fs-1 mb-4 pb-5'>Inscrivez-vous sur BIZCONNECT</h2>

                    <form>
                        <div className='inputFile mb-2'>
                            <span className=' text-black-50 fs-6 fw-medium'>Photo de profil</span>
                            <label role='button' htmlFor='photo' className='mt-2 d-flex flex-column justify-content-center align-items-center bg-body-secondary rounded-circle mx-auto overflow-hidden' style={{width:"10rem",height:"10rem"}}>
                                {!photo?<i className=" ri-camera-line ri-5x opacity-50"></i>:<img alt='' src={URL.createObjectURL(photo)} className='object-fit-cover w-100 h-100' />}
                            </label>
                            <input name='photo' id='photo' onChange={(e)=>{setPhoto(e.target.files[0])}} type='file' accept='image/*' max-file-size="1331200" className='w-100 d-none'/>
                        </div>
                        <div className='d-flex justify-content-center align-items-center pb-1 border-bottom border-1 border-black'>
                            <label htmlFor='f_name'  className='px-2 py-1 bgcolor-primary d-flex justify-content-center align-items-center ms-2'>
                                <i className='ri-user-3-line text-white'></i>
                            </label>
                            <input type='text' value={f_name} onChange={(e)=>setFname(e.target.value)} id='f_name' placeholder="Nom" className='form-control border-0'/>
                        </div>
                        <div className='d-flex justify-content-center align-items-center pb-1 border-bottom border-1 border-black mt-4'>
                            <label htmlFor='l_name'  className='px-2 py-1 bgcolor-primary d-flex justify-content-center align-items-center ms-2'>
                                <i className='ri-user-3-line text-white'></i>
                            </label>
                            <input type='text' value={l_name} onChange={(e)=>setLname(e.target.value)} id='l_name' placeholder="Prénom" className='form-control border-0'/>
                        </div>
                        <div className='d-flex justify-content-center align-items-center pb-1 border-bottom border-1 border-black mt-4'>
                            <label htmlFor='email'  className='px-2 py-1 bgcolor-primary d-flex justify-content-center align-items-center ms-2'>
                                <i className=' ri-mail-line text-white'></i>
                            </label>
                            <input type='email' value={email} onChange={(e)=>setEmail(e.target.value)} id='email' placeholder="Adresse email" className='form-control border-0'/>
                        </div>
                        <div className='d-flex justify-content-center align-items-center pb-1 border-bottom border-1 border-black mt-4'>
                            <label htmlFor='phone'  className='px-2 py-1 bgcolor-primary d-flex justify-content-center align-items-center ms-2'>
                                <i className=' ri-phone-line text-white'></i>
                            </label>
                            <PhoneInput
                                className=' w-100 border-0'
                                defaultCountry="cm"
                                value={phone}
                                onChange={(phone) => setPhone(phone)}
                            />
                        </div>
                        <div className='d-flex justify-content-center align-items-center pb-1 border-bottom border-1 border-black mt-4'>
                            <label htmlFor='date_naiss'  className='px-2 py-1 bgcolor-primary d-flex justify-content-center align-items-center ms-2'>
                                <i className='ri-calendar-event-line text-white'></i>
                            </label>
                            <input type='date' value={date_naiss} onChange={(e)=>setDateNaiss(e.target.value)} id='date_naiss' placeholder="Date de naissance" className='form-control border-0'/>
                        </div>
                        <div className='d-flex justify-content-center align-items-start pb-1 border-bottom border-1 border-black mt-4'>
                            <label htmlFor='description' className='px-2 py-1 bgcolor-primary d-flex justify-content-center align-items-center ms-2'>
                                <i className=' ri-message-2-line text-white'></i>
                            </label>
                            <textarea value={description} onChange={(e)=>setDescription(e.target.value)} id='description' placeholder="Bio, à propos de vous" className='form-control border-0' style={{minHeight:"6rem"}}></textarea>
                        </div>
                        {/* <div style={{ margin: '100px' }}>
                            <Map
                                // google={this.props.google}
                                center={{lat: 18.5204, lng: 73.8567}}
                                height='300px'
                                zoom={15}
                            />
                        </div> */}

                        <div className='d-flex justify-content-center align-items-center pb-1 border-bottom border-1 border-black mt-4'>
                            <label htmlFor='mdp'  className='px-2 py-1 bgcolor-primary d-flex justify-content-center align-items-center ms-2'>
                                <i className='ri-lock-2-line text-white'></i>
                            </label>
                            <input type='password' value={mdp} onChange={(e)=>setMdp(e.target.value)} id='mdp' placeholder="Mot de passe" className='form-control border-0'/>
                        </div>
                        <input type='submit' value={'Créer un compte'} onClick={handleLog} className=' mt-3 py-2 border-0 bgcolor-primary w-100 text-white fw-bold'/>
                        <div className=' text-end mt-3' role='button' onClick={()=>setRegister(false)}>
                            <small role='button' className='fst-italic color-primary fs-6'>Déjà un compte ? <span className=' text-decoration-underline'>Se connecter !</span> </small>
                        </div>
                    </form>
                </div>
            </div>

            <ToastContainer pauseOnFocusLoss={false} hideProgressBar={true}/>
        </div>
    )
}

export default Register