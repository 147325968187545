import React from 'react'

const IconText = ({iconClass, textClass, text}) => {
    return (
        <div className=' d-flex justify-content-center align-items-center'>
            <i className={"me-2 " + (iconClass)}></i>
            <span className={textClass}>{text}</span>
        </div>
    )
}

export default IconText
