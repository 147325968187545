import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import biz from '../api/axiosConfig'
import { checkUserPasswordUrl, updateUserPasswordUrl } from '../api/enpoint'

const Password = () => {


    const[oldMdp, setOlMdp] = useState('')
    const[mdp, setMdp] = useState('')
    const[mdp2, setMdp2] = useState('')

    
    const { user } = useSelector(state => state.user)


    const toastload = useRef(null)
    const handleLog = (e) =>{
        e.preventDefault()
        if(mdp && oldMdp && mdp2){
            toastload.current = toast.loading("Veuillez patienter...",{position: toast.POSITION?.TOP_LEFT})
            
            biz.post(checkUserPasswordUrl, {password: oldMdp})
            .then((res)=>{
                if (!res.data){
                    toast.update(toastload.current, {render:"Ancien mot de passe incorrect", type: "error", isLoading: false, hideProgressBar: false, closeOnClick: true})
                }else if (mdp.length < 8) {
                    toast.update(toastload.current, {render:"Nouveau mot de passe faible", type: "error", isLoading: false, hideProgressBar: false, closeOnClick: true})
                }else if (mdp !== mdp2) {
                    toast.update(toastload.current, {render:"Les 02 mots de passe ne concordent pas", type: "error", isLoading: false, hideProgressBar: false, closeOnClick: true})
                }else{
                    biz.put(updateUserPasswordUrl+user.id, {password: mdp})
                    .then(res =>{
                        toast.update(toastload.current, {render: <span>Mot de passe modifié<br/>{res.data.name}</span>,type: "success",isLoading: false, hideProgressBar: false})
                        setMdp('')
                        setMdp2('')
                        setOlMdp('')
                    })
                    .catch(err =>{
                        toast.update(toastload.current, {render: err.response.data.email ?"email déjà existant ! Se connecter ?":err.response.data.pass?"Mot de passe incorrect":"Une erreur c'est produite", type: "error", isLoading: false, hideProgressBar: false, closeOnClick: true})
                        console.log('err',err.response.data)
                    })
                }
            })
            .catch(()=>{
                toast.update(toastload.current, {render:"Une erreur c'est produite", type: "error", isLoading: false, hideProgressBar: false, closeOnClick: true})
            })
        }else{
            toastload.current = toast.error("Remplir tout les champs", {position: toast.POSITION?.TOP_LEFT, isLoading: false, autoClose: false,hideProgressBar: false, closeOnClick: true})
        }
    }



    return (
        <div>
            <form className=' mx-auto' style={{maxWidth:"500px"}}>
                <div className='d-flex justify-content-center align-items-center pb-1 border-bottom border-1 border-black mt-4'>
                    <label htmlFor='oldMdp'  className='px-2 py-1 bgcolor-primary d-flex justify-content-center align-items-center ms-2'>
                        <i className='ri-lock-password-line text-white'></i>
                    </label>
                    <input type='text' value={oldMdp} onChange={(e)=>setOlMdp(e.target.value)} id='oldMdp' placeholder="Ancien mot de passe" className='form-control border-0'/>
                </div>
                <div className='d-flex justify-content-center align-items-center pb-1 border-bottom border-1 border-black mt-4'>
                    <label htmlFor='mdp'  className='px-2 py-1 bgcolor-primary d-flex justify-content-center align-items-center ms-2'>
                        <i className='ri-lock-2-line text-white'></i>
                    </label>
                    <input type='password' value={mdp} onChange={(e)=>setMdp(e.target.value)} id='mdp' placeholder="Nouveau mot de passe" className='form-control border-0'/>
                </div>
                <div className='d-flex justify-content-center align-items-center pb-1 border-bottom border-1 border-black mt-4'>
                    <label htmlFor='mdp2'  className='px-2 py-1 bgcolor-primary d-flex justify-content-center align-items-center ms-2'>
                        <i className='ri-lock-2-line text-white'></i>
                    </label>
                    <input type='password' value={mdp2} onChange={(e)=>setMdp2(e.target.value)} id='mdp2' placeholder="Confirmez le mot de passe" className='form-control border-0'/>
                </div>
                <input type='submit' value={'Changer le mot de passe'} onClick={handleLog} className=' mt-3 py-2 border-0 bgcolor-primary w-100 text-white fw-bold'/>
            </form>

            <ToastContainer pauseOnFocusLoss={false} hideProgressBar={true}/>
        </div>
    )
}

export default Password