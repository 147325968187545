import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import LoginModal from '../components/LoginModal'
import Loading from './Loading'
import Reload from './Reload'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUser } from '../redux/slices/userGet'
import Information from './Information'
import Password from './Password'
import DeleteAccount from './DeleteAccount'
import BuznessList from './BuznessList'
import FavoriteList from './FavoriteList'

const Account = () => {

    const {part} = useParams()

    const navigate = useNavigate()

    const dispatch = useDispatch()

    const { user, loadUser, errGetUser } = useSelector(state => state.user)

    useEffect(()=>{
        dispatch(fetchUser())
    },[dispatch])
    

    const[title, setTitle] = useState('')
    const[titleLink, setTitleLink] = useState(<></>)
    const[view, setView] = useState(<></>)
    useEffect(()=>{
        switch(part){
            case "profile":
                setTitle("Informations personnelles")
                setTitleLink("Informations")
                setView(<Information/>)
                break;
            case "bizness":
                setTitle("Vos biz'ness")
                setTitleLink("Vos Biz'ness")
                setView(<BuznessList/>)
                break
            case "favorites":
                setTitle("Vos favories")
                setTitleLink("Vos favories")
                setView(<FavoriteList/>)
                break
            case "password":
                setTitle("Changer de mot de passe")
                setTitleLink("Mot de passe")
                setView(<Password/>)
                break
            case "delete":
                setTitle("Supprimer votre compte")
                setTitleLink("Supprimer le compte")
                setView(<DeleteAccount/>)
                break
            default:
                setTitle("")
        }
    },[part])

    return (
        loadUser?
            <Loading/>
        :
        (!user )?(
            <LoginModal open={true} onClose={()=>navigate('/')} />
        ):
        errGetUser?
            <Reload/>
        :
        (
            <div id='account' className=' bg-body-tertiary pt-5' style={{minHeight:"100vh"}}>
                <div className='py-4'>
                    <div className=' container my-5'>
                        <div className=' row justify-content-around align-items-start'>
                            <div className=' col-md-3'>
                                <div className=' bg-white rounded-2 shadow d-md-block d-none'>
                                    <ul className=' list-unstyled mb-0'>
                                        <li>
                                            <NavLink to={'/account/profile'} className=' d-flex justify-content-start align-items-center text-black py-2 px-3 border-bottom'>
                                                <i className='fa fa-user me-2 mb-1'></i>
                                                <span>Informations</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink  to={'/account/bizness'} className=' d-flex justify-content-start align-items-center text-black py-2 px-3 border-bottom'>
                                                <i className='fa fa-store me-2 mb-1'></i>
                                                <span>Vos Biz'ness</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink  to={'/account/favorites'} className=' d-flex justify-content-start align-items-center text-black py-2 px-3 border-bottom'>
                                                <i className='fa fa-heart me-2 mb-1'></i>
                                                <span>Vos favories</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={'/account/password'} className=' d-flex justify-content-start align-items-center text-black py-2 px-3 border-bottom'>
                                                <i className='fa fa-lock me-2 mb-1'></i>
                                                <span>Mot de passe</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink  to={'/account/delete'} className=' d-flex justify-content-start align-items-center text-danger py-2 px-3 border-bottom'>
                                                <i className='fa fa-trash me-2 mb-1'></i>
                                                <span>Supprimer le compte</span>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>

                                <div className=' d-md-none mb-3'>
                                    <div className=" d-flex justify-content-start align-items-center" role='button' data-bs-toggle="collapse" data-bs-target="#subnav" >
                                        <i className=' fa fa-bars fs-5 me-2'></i>
                                        <span className=' fw-bold color-primary'>{titleLink}</span>
                                    </div>
                                    <div className="collapse bg-white rounded-2 shadow px-3 py-2" id="subnav">
                                        <ul className=' list-unstyled mb-0'>
                                            <li>
                                                <NavLink to={'/account/profile'} className=' d-flex justify-content-start align-items-center text-black py-2 px-3 border-bottom'>
                                                    <i className='fa fa-user me-2 mb-1'></i>
                                                    <span>Informations</span>
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink  to={'/account/bizness'} className=' d-flex justify-content-start align-items-center text-black py-2 px-3 border-bottom'>
                                                    <i className='fa fa-store me-2 mb-1'></i>
                                                    <span>Vos Biz'ness</span>
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink  to={'/account/favorites'} className=' d-flex justify-content-start align-items-center text-black py-2 px-3 border-bottom'>
                                                    <i className='fa fa-heart me-2 mb-1'></i>
                                                    <span>Vos favories</span>
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={'/account/password'} className=' d-flex justify-content-start align-items-center text-black py-2 px-3 border-bottom'>
                                                    <i className='fa fa-lock me-2 mb-1'></i>
                                                    <span>Mot de passe</span>
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink  to={'/account/delete'} className=' d-flex justify-content-start align-items-center text-danger py-2 px-3 border-bottom'>
                                                    <i className='fa fa-trash me-2 mb-1'></i>
                                                    <span>Supprimer le compte</span>
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                            </div>

                            <div className='col'>
                                <div className=' py-2 bg-white rounded-2 shadow'>
                                    <h2 className=' px-3'>{title}</h2>
                                    <hr/>
                                    <div className=' px-3 mt-2'>
                                        {view}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    )
}

export default Account