import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import biz from "../../api/axiosConfig";
import { likedBussinessByUserUrl } from "../../api/enpoint";


//Action
export const fetchUserFavorite = createAsyncThunk('fetchUserFavorite', async (id)=>{
    const resp = await biz.get(likedBussinessByUserUrl+id)
    return resp.data;
})


const likedByUserSlice = createSlice({
    name:'fetchUserFavorite',
    initialState:{
        loadFavoriteU: false,
        favoriteU: null,
        errFavoriteU: null,
    },
    extraReducers: (builder) =>{
        builder
        .addCase(fetchUserFavorite.pending, (state, action) =>{
            state.loadFavoriteU = true
            state.favoriteU = null
            state.errFavoriteU = null
        })
        .addCase(fetchUserFavorite.fulfilled, (state, action) =>{
            state.loadFavoriteU = false
            state.favoriteU = action.payload
            state.errFavoriteU = null
        })
        .addCase(fetchUserFavorite.rejected, (state, action) =>{
            state.loadFavoriteU = false
            if (action.error.message === "Request failed with status code 401") {
                state.errFavoriteU = 'Access Denied'
            } else {
                state.errFavoriteU = action.error.message
            }
        })
    }
})

export default likedByUserSlice.reducer;