import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import biz from "../../api/axiosConfig";
import { getBussinessesUrl } from "../../api/enpoint";


//Action
export const fetchBussinesses = createAsyncThunk('fetchBussinesses', async ()=>{
    const resp = await biz.get(getBussinessesUrl)
    return resp.data;
})


const getBussinessesSlice = createSlice({
    name:'fetchBussinesses',
    initialState:{
        loadBussinesses: false,
        bussinesses: null,
        errGetBussinesses: null,
    },
    extraReducers: (builder) =>{
        builder
        .addCase(fetchBussinesses.pending, (state, action) =>{
            state.loadBussinesses = true
            state.bussinesses = null
            state.errGetBussinesses = null
        })
        .addCase(fetchBussinesses.fulfilled, (state, action) =>{
            state.loadBussinesses = false
            state.bussinesses = action.payload
            state.errGetBussinesses = null
        })
        .addCase(fetchBussinesses.rejected, (state, action) =>{
            state.loadBussinesses = false
            console.log(action.error);
            if (action.error.message === "Request failed with status code 401") {
                state.errGetBussinesses = 'Access Denied'
            } else {
                state.errGetBussinesses = action.error.message
            }
        })
    }
})

export default getBussinessesSlice.reducer;