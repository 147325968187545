import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import biz from '../api/axiosConfig'
import { baseURL, updateUserUrl } from '../api/enpoint'
import { encryptData } from '../api/encryptData'
import { fetchUser } from '../redux/slices/userGet'
import { PhoneInput } from 'react-international-phone'
import { profile } from '../constants/files'
import DateObject from 'react-date-object'

const Information = () => {


    const dispatch = useDispatch()

    const { user } = useSelector(state => state.user)
    
    const[f_name, setFname] = useState('')
    const[l_name, setLname] = useState('')
    const[phone, setPhone] = useState('')
    const[description, setDescription] = useState()
    const[photo, setPhoto] = useState()
    const[photoP, setPhotoP] = useState()
    const[date_naiss, setDateNaiss] = useState('')
    const[location, setLocation] = useState('Douala')

    
    const navigate = useNavigate()

    useEffect(()=>{
        const date = new DateObject(user.date_naisss)
        setFname(user.f_name)
        setLname(user.l_name)
        setPhone(user.telephone)
        setDescription(user.description)
        setPhoto(user.photo)
        setDateNaiss(date.format("YYYY-MM-DD"))
        setLocation(user.location)
    },[user])

    
    const toastload = useRef(null)
    const handleLog = (e) =>{
        e.preventDefault()
        if(f_name && l_name && phone && location){
            toastload.current = toast.loading("Veuillez patienter...",{position: toast.POSITION?.TOP_LEFT})
            const formData = new FormData()

            formData.append('f_name', f_name)
            formData.append('l_name', l_name)
            formData.append('telephone', phone)
            formData.append('date_naisss', date_naiss)
            formData.append('description', description)
            formData.append('location', location)
            formData.append('photo', photoP)

            biz.put(updateUserUrl+user.id, formData)
            .then(res =>{
                toast.update(toastload.current, {render: <span>Informations modifiées {res.data.name}</span>,type: "success",isLoading: false, hideProgressBar: false})
                encryptData('token',res.data.token)
                dispatch(fetchUser())
                navigate('/')
            })
            .catch(err =>{
                toast.update(toastload.current, {render: err.response.data.email ?"email déjà existant ! Se connecter ?":"Une erreur c'est produite", type: "error", isLoading: false, hideProgressBar: false, closeOnClick: true})
                console.log('err',err.response.data)
            })
        }else{
            toastload.current = toast.error("Remplir tout les champs", {position: toast.POSITION?.TOP_LEFT, isLoading: false, autoClose: false,hideProgressBar: false, closeOnClick: true})
        }
    }


    return (
        <div className=' mx-auto'>
            <form className=' row justify-content-around align-items-center'>
                <div className='inputFile mb-2 text-center'>
                    <span className=' text-black-50 fs-6 fw-medium'>Photo de profil</span>
                    <label role='button' htmlFor='photo' className='mt-2 d-flex flex-column justify-content-center align-items-center bg-body-secondary rounded-circle mx-auto overflow-hidden' style={{width:"10rem",height:"10rem"}}>
                        <img src={photoP? URL.createObjectURL(photoP):!photo?profile:`${baseURL}${photo}`} alt={photo} className='object-fit-cover w-100 h-100' />
                    </label>
                    <input name='photo' id='photo' onChange={(e)=>{setPhotoP(e.target.files[0])}} type='file' accept='image/*' max-file-size="1331200" className='w-100 d-none'/>
                </div>
                <div className=' col-md-6 col-12'>
                    <div className='d-flex justify-content-center align-items-center pb-1 border-bottom border-1 border-black mt-4'>
                        <label htmlFor='f_name'  className='px-2 py-1 bgcolor-primary d-flex justify-content-center align-items-center ms-2'>
                            <i className='ri-user-3-line text-white'></i>
                        </label>
                        <input type='text' value={f_name} onChange={(e)=>setFname(e.target.value)} id='f_name' placeholder="Nom" className='form-control border-0'/>
                    </div>
                </div>
                <div className=' col-md-6 col-12'>
                    <div className='d-flex justify-content-center align-items-center pb-1 border-bottom border-1 border-black mt-4'>
                        <label htmlFor='l_name'  className='px-2 py-1 bgcolor-primary d-flex justify-content-center align-items-center ms-2'>
                            <i className='ri-user-3-line text-white'></i>
                        </label>
                        <input type='text' value={l_name} onChange={(e)=>setLname(e.target.value)} id='l_name' placeholder="Prénom" className='form-control border-0'/>
                    </div>
                </div>
                <div className=' col-md-6 col-12'>
                    <div className='d-flex justify-content-center align-items-center pb-1 border-bottom border-1 border-black mt-4'>
                        <label htmlFor='phone'  className='px-2 py-1 bgcolor-primary d-flex justify-content-center align-items-center ms-2'>
                            <i className=' ri-phone-line text-white'></i>
                        </label>
                        <PhoneInput
                            className=' w-100 border-0'
                            defaultCountry="cm"
                            value={phone}
                            onChange={(phone) => setPhone(phone)}
                        />
                    </div>
                </div>
                <div className=' col-md-6 col-12'>
                    <div className='d-flex justify-content-center align-items-center pb-1 border-bottom border-1 border-black mt-4'>
                        <label htmlFor='date_naiss'  className='px-2 py-1 bgcolor-primary d-flex justify-content-center align-items-center ms-2'>
                            <i className='ri-calendar-event-line text-white'></i>
                        </label>
                        <input type='date' value={date_naiss} onChange={(e)=>setDateNaiss(e.target.value)} id='date_naiss' placeholder="Date de naissance" className='form-control border-0'/>
                    </div>
                </div>
                <div className='col'>
                    <div className='d-flex justify-content-center align-items-start pb-1 border-bottom border-1 border-black mt-4'>
                        <label htmlFor='description' className='px-2 py-1 bgcolor-primary d-flex justify-content-center align-items-center ms-2'>
                            <i className=' ri-message-2-line text-white'></i>
                        </label>
                        <textarea value={description} onChange={(e)=>setDescription(e.target.value)} id='description' placeholder="Bio, à propos de vous" className='form-control border-0' style={{minHeight:"6rem"}}></textarea>
                    </div>
                </div>
                {/* <div style={{ margin: '100px' }}>
                    <Map
                        // google={this.props.google}
                        center={{lat: 18.5204, lng: 73.8567}}
                        height='300px'
                        zoom={15}
                    />
                </div> */}
                <div className=' col-12'>
                    <input type='submit' value={'Mettre à jour'} onClick={handleLog} className=' mt-3 py-2 border-0 bgcolor-primary text-white fw-bold col-12'/>
                </div>
            </form>

            <ToastContainer pauseOnFocusLoss={false} hideProgressBar={true}/>
        </div>
    )
}

export default Information