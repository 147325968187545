import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';

const SECRET_KEY = 'mysecretkey'; 


export const encryptData =(name,data)=> {
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
    if (Cookies.get(name)) {
        Cookies.remove(name)
        Cookies.set(name,encrypted)
    } else {
        Cookies.set(name,encrypted)
    }
}


export const decryptData = (name) => {
    const encrypted = Cookies.get(name);
    const decrypted = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(CryptoJS.enc.Utf8);
    return JSON.parse(decrypted);
}