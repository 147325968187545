import React, { useRef, useState } from 'react'
import Modal from 'react-responsive-modal'
import { useNavigate } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { bg_login, logo_black } from '../constants/files'
import { ToastContainer, toast } from 'react-toastify'
import biz from '../api/axiosConfig'
import { encryptData } from '../api/encryptData'
import { useDispatch } from 'react-redux'
import { fetchUser } from '../redux/slices/userGet'
import { loginUserUrl } from '../api/enpoint'
import Register from './Register'

const Login = () => {

    const dispatch = useDispatch()
    
    const[userName, setUserName] = useState('')
    const[mdp, setMdp] = useState('')
    const [update, setUpdate] = useState(false)
    const [register, setRegister] = useState(false)
    
    const navigate = useNavigate()

    // constante de vérifiaction d'ouverture et de fermeture de la modal
    const [read, setRead] = useState(false)
    const [ok, setOk] = useState(false)
    const [error, setError] = useState(false)



    const toastload = useRef(null)
    const handleLog = (e) =>{
        e.preventDefault()
        if(userName && mdp){
            toastload.current = toast.loading("Veuillez patienter...",{position: toast.POSITION?.TOP_LEFT})
            biz.post(loginUserUrl, {email: userName, password: mdp})
            .then(res =>{
                toast.update(toastload.current, {render: <span>Vous êtes connectés<br/> Bienvenu {res.data.name}</span>,type: "success",isLoading: false, hideProgressBar: false})
                encryptData('token',res.data.token)
                dispatch(fetchUser())
                // window.location.reload()
                navigate('/')
            })
            .catch(err =>{
                toast.update(toastload.current, {render: err.response?.data.email ?"email incorrect":err.response?.data.pass?"Mot de passe incorrect":"Une erreur c'est produite", type: "error", isLoading: false, hideProgressBar: false, closeOnClick: true})
                console.log('err',err)
            })
        }else{
            toastload.current = toast.error("Remplir tout les champs", {position: toast.POSITION?.TOP_LEFT, isLoading: false, autoClose: false,hideProgressBar: false, closeOnClick: true})
        }
    }



    //Générer un mot de passe aléatoire
    const chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz@#$";
    const string_length = 8;
    var randomstring = '';
    for (var i=0; i<string_length; i++) {
        const rnum = Math.floor(Math.random() * chars.length);
        randomstring += chars.substring(rnum,rnum+1);
    }

    
    const sendEmail = async(e) => {
        e.preventDefault()
        if (window.Email) {
            setRead(true)
            window.Email.send({
                SecureToken: "569fda13-471b-447c-9a3a-e813c707ab2a",
                From : 'pangoafricadev@gmail.com',
                To : 'pangoafricadev@gmail.com',
                Subject : "Réinitialisation du mot de passe",
                Body : `<div>
                            <h1><strong>Nouveau mot de passe</strong></h1>
                            <p>Le mot de passe du site web admin de Eventify a été réinitialiser !</p><br/><br/>
                            <p>Le nouveau mot de passe est donc: ${randomstring} </p><br/><br/>
                            <p>Vous pouvez vous connecter avec ce mot de passe et le changer après votre connexion</p>
                        </div>`
            }).then((message) =>{
                // axios.put(`${URL_API_USER}${user[0].id}`,{pwd: randomstring})
                // .then(()=>{
                //     axios.get(URL_API_USER).then((response)=>setUser(response.data))
                // })
                // .catch(() => {setError(true)})
                // return(
                //     console.log(message),
                //     setRead(false),
                //     message === 'OK'? setOk(true): setErr(true)
                // )
            })
        }
    }

    
    return (
        <div className='login-bg'>
            <div className='container'>
                {
                    !register?
                        <div className='login d-flex justify-content-center align-items-center'>
                            <div className='row bg-white justify-content-center align-items-center'>
                                <div className='col-lg-8 px-0 col-12 position-relative h-100'>
                                    <img alt='connexion à EAR actu' src={bg_login} className='img-fluid login-img object-fit-cover w-100 h-100' />
                                    <div className=' position-absolute bg-black bg-opacity-50 top-0 end-0 bottom-0 start-0 d-flex justify-content-center align-items-center flex-column text-center text-white'>
                                        <h3>BIENVENUE SUR BIZCONNECT</h3>
                                        <small>Connectez-vous pour ne user de toutes les fonctionnalités</small>
                                        <small className='position-absolute bottom-0 pb-2'>Visitez le site biz-connect.com</small>
                                    </div>
                                </div>
                                <div className='col-lg-4 px-0 col-12'>
                                    <div className='px-3 py-2'>
                                        <div className='d-flex flex-column justify-content-center align-items-center'>
                                            <img alt='connexion à EAR actu' src={logo_black} className='img-fluid login-brand' />
                                        </div>
                                        <h3 className='fw-bolder text-center color-primary mt-3'>CONNEXION</h3>
                                        <div className='mt-5'>
                                            <form>
                                                <div className='d-flex justify-content-center align-items-center pb-1 border-bottom border-1 border-black'>
                                                    <label htmlFor='login'  className='px-2 py-1 bgcolor-primary d-flex justify-content-center align-items-center ms-2'>
                                                        <i className='ri-user-3-line text-white'></i>
                                                    </label>
                                                    <input type='text' value={userName} onChange={(e)=>setUserName(e.target.value)} id='login' placeholder="Nom d'utisateur" className='form-control border-0'/>
                                                </div>
                                                <div className='d-flex justify-content-center align-items-center pb-1 border-bottom border-1 border-black mt-3'>
                                                    <label htmlFor='mdp'  className='px-2 py-1 bgcolor-primary d-flex justify-content-center align-items-center ms-2'>
                                                        <i className='ri-lock-2-line text-white'></i>
                                                    </label>
                                                    <input type='password' value={mdp} onChange={(e)=>setMdp(e.target.value)} id='mdp' placeholder="Mot de passe" className='form-control border-0'/>
                                                </div>
                                                <div className='text-end mt-1 mb-2' role='button' onClick={()=>setUpdate(true)}>
                                                    <small className='fst-italic color-primary'>Mot de passe oublié ?</small>
                                                </div>
                                                <input type='submit' value={'Se connecter'} onClick={handleLog} className=' mt-3 py-2 border-0 bgcolor-primary w-100 text-white fw-bold'/>
                                                <div className=' text-end mt-3' role='button' onClick={()=>setRegister(true)}>
                                                    <small role='button' className='fst-italic color-primary fs-6'>Pas encore de compte ? <span className=' text-decoration-underline'>Créer un compte !</span> </small>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    :<Register setRegister={setRegister} />
                }
            </div>


            <Modal open={update} onClose={()=>{setUpdate(false)}} center={true} showCloseIcon={true}>
                <form className='p-3 px-4 text-center'>
                    <p> Appuyer sur<span className='span-link'> Réinitialiser le mot de passe </span> <br/> pour recevoir un nouveau mot de passe par E-mail </p>
                    {
                        read?<ClipLoader
                        color={'#ff4a19'}
                        size={30}
                        cssOverride={{display: "flex", margin: "0 auto"}}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        className='text-center'
                        />:ok?
                        <Modal open={ok} onClose={()=>{setOk(false)}} showCloseIcon={true} center={true}>
                            <h2 className={'text-center text-success'}><i className={"ri-checkbox-circle-fill fs-0"}></i></h2>
                            <p className='text-center'>
                                Mot de passe réinitialiser ! Veuillez consulter votre boite mail.
                            </p>
                        </Modal>:
                        error?
                        <Modal open={error} onClose={()=>{setError(false)}} showCloseIcon={true} center={true}>
                            <h2 className={'text-center color-primary'}><i className={"ri-close-circle-fill fs-0"}></i></h2>
                            <p className='text-center'>
                                Une erreur c'est produite lors de l'envoie. Veuillez reéssayer !
                            </p>
                        </Modal>:
                        <input type="submit" onClick={sendEmail} className="bg-success border-0 px-4 py-2 text-white rounded-3" value={'Réinitialiser le mot de passe'}/>
                    }
                </form>
            </Modal>
            <ToastContainer pauseOnFocusLoss={false} hideProgressBar={true}/>
        </div>
    )
}

export default Login