/* eslint-disable eqeqeq */
import React, { useState } from 'react'
import { baseURL } from '../api/enpoint'
import Modal from 'react-responsive-modal'
import RateForm from './RateForm'
import { useSelector } from 'react-redux'

const RateCard = ({photo,l_name,notice,rating,createdAt,bizId,id,creatorId}) => {

    const { user } = useSelector(state => state.user)

    const[open, setOpen] = useState(false)

    return (
        <>
            <div className=' mb-4'>
                <div className=' d-flex align-items-center'>
                    <div className=' d-flex justify-content-center align-items-center overflow-hidden rounded-circle' style={{width:"2.3rem",height:"2.3rem"}}>
                        <img alt='' src={baseURL + photo} className=' object-fit-cover w-100 h-100' />
                    </div>
                    <span className=' ms-2'>{l_name}</span>
                </div>
                <div className=' d-flex align-items-center mt-2'>
                    <div className=' d-flex justify-content-center align-items-center'>
                        {Array(rating).fill(<i className=' fa fa-star color-primary fs-6s'></i>)}
                        {Array(5 - rating).fill(<i className=' fa fa-star text-secondary fs-6s'></i>)}
                    </div>
                    <small className=' ms-2'>{createdAt}</small>
                </div>
                <p className=' text-black-50 mt-2'>{notice} </p>
                {
                    (user?.id == creatorId) &&
                    <div className=' d-flex justify-content-end align-items-center'>
                        <button onClick={()=>setOpen(true)} className=' btn border-1 bordercolor-primary rounded-pill px-3 py-1'>Mettre à jour</button>
                    </div>
                }
            </div>

            <Modal classNames={{modal:"w-100"}} center={true} open={open} onClose={()=>setOpen(false)} >
                <div className=' p-md-2 p-1'>
                    <h4 className=' fs-3 fw-bold px-md-4 px-2'>Modifier votre avis</h4>
                    <RateForm st={rating} cm={notice} update={true} commentId={id} bizId={bizId} />
                </div>
            </Modal>
        </>
    )
}

export default RateCard