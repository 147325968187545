import React, { useEffect, useRef, useState } from 'react'
import { Rating } from 'primereact/rating';
import { toast } from 'react-toastify';
import biz from '../api/axiosConfig';
import { postComment, updateComment } from '../api/enpoint';
import { useDispatch } from 'react-redux';
import { fetchBussiness } from '../redux/slices/bussinessGetOne';

const RateForm = ({bizId,commentId,st,cm,add,update}) => {

    const[star, setStar] = useState(1)
    const[comment, setComment] = useState("")

    useEffect(() => {
        if (st) {
            setStar(st)
        }
        if (cm) {
            setComment(cm)
        }
    }, [cm,st]);

    const dispatch = useDispatch()

    const toastload = useRef(null)
    const handleAdd = () =>{
        if(comment){
            toastload.current = toast.loading("Veuillez patienter...",{position: toast.POSITION?.TOP_LEFT})

            biz.post(postComment+bizId, {notice: comment, rating: star})
            .then(res =>{
                toast.update(toastload.current, {render: <span>Commentaire ajoutée</span>,type: "success",isLoading: false, hideProgressBar: false, closeOnClick: true})
                dispatch(fetchBussiness(bizId))
                setComment('')
                setStar(1)
            })
            .catch(err =>{
                toast.update(toastload.current, {render: err.response.data.data ?"Veuillez laisser votre avis":"Une erreur c'est produite", type: "error", isLoading: false, hideProgressBar: false, closeOnClick: true})
                console.log('err',err.response.data)
            })
        }else{
            toastload.current = toast.error("Veuillez laisser votre avis", {position: toast.POSITION?.TOP_LEFT, isLoading: false, autoClose: false,hideProgressBar: false, closeOnClick: true})
        }
    }


    const handleModify = () =>{
        if(comment){
            toastload.current = toast.loading("Veuillez patienter...",{position: toast.POSITION?.TOP_LEFT})

            biz.put(updateComment+commentId, {notice: comment, rating: star})
            .then(res =>{
                toast.update(toastload.current, {render: <span>Commentaire Modifié</span>,type: "success",isLoading: false, hideProgressBar: false, closeOnClick: true})
                dispatch(fetchBussiness(bizId))
                setComment('')
                setStar(1)
            })
            .catch(err =>{
                toast.update(toastload.current, {render: err.response?.data?.data ?"Veuillez laisser votre avis":"Une erreur c'est produite", type: "error", isLoading: false, hideProgressBar: false, closeOnClick: true})
                console.log('err',err.response)
            })
        }else{
            toastload.current = toast.error("Veuillez laisser votre avis", {position: toast.POSITION?.TOP_LEFT, isLoading: false, autoClose: false,hideProgressBar: false, closeOnClick: true})
        }
    }


    return (
        <div style={{maxWidth:"800px"}}>
            <textarea value={comment} onChange={(e)=>{setComment(e.target.value)}} className=' mb-3 form-control border-2' style={{minHeight:"100px"}} placeholder='Que pensez-vous ?'></textarea>
            <Rating value={star} onChange={(e) => setStar(e.value)} cancel={false} />
            <div className=' d-flex justify-content-end'>
                <button onClick={()=>{add?handleAdd():update?handleModify():handleAdd()}} className=' border-0 rounded px-3 py-1 bgcolor-primary text-white'>{update?"Mettre à jour":"Ajouter"}</button>
            </div>
        </div>
    )
}

export default RateForm