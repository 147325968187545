import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import biz from "../../api/axiosConfig";
import { getUserUrl } from "../../api/enpoint";


//Action
export const fetchUser = createAsyncThunk('fetchUser', async (data)=>{
    const resp = await biz.get(getUserUrl, data)
    return resp.data;
})


const getUserSlice = createSlice({
    name:'fetchUser',
    initialState:{
        loadUser: false,
        user: null,
        errGetUser: null,
    },
    extraReducers: (builder) =>{
        builder
        .addCase(fetchUser.pending, (state, action) =>{
            state.loadUser = true
            state.user = null
            state.errGetUser = null
        })
        .addCase(fetchUser.fulfilled, (state, action) =>{
            state.loadUser = false
            state.user = action.payload
            state.errGetUser = null
        })
        .addCase(fetchUser.rejected, (state, action) =>{
            state.loadUser = false
            if (action.error.message === "Request failed with status code 401") {
                state.errGetUser = 'Access Denied'
            } else {
                state.errGetUser = action.error.message
            }
        })
    }
})

export default getUserSlice.reducer;