/* eslint-disable eqeqeq */
import React, { useEffect, useRef, useState } from 'react'
import { baseURL, deleteProductUrl, updateProductUrl } from '../api/enpoint'
import Modal from 'react-responsive-modal'
import { SpeedDial } from 'primereact/speeddial'
import { Tooltip } from 'primereact/tooltip'
import RedStar from './RedStar'
import { toast } from 'react-toastify'
import biz from '../api/axiosConfig'
import { useDispatch, useSelector } from 'react-redux'
import { fetchBussiness } from '../redux/slices/bussinessGetOne'

const ProductCard = ({image,label,description,id,bizId,creatorId}) => {

    const[add, setAdd] = useState(false)
    const[modify, setModify] = useState(false)
    const[del, setDel] = useState(false)

    const[labelM, setLabelM] = useState('')
    const[descriptionM, setDescriptionM] = useState('')
    const[imageMp, setImageMp] = useState()

    const { user } = useSelector(state => state.user)

    const dispatch = useDispatch()

    useEffect(() => {
        setLabelM(label)
        setDescriptionM(description)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const items = [
        {
            label: 'Voir',
            icon: 'fa-regular fa-eye text-white',
            command: () => {setAdd(true)}
        },
        {
            label: 'Modifier',
            icon: 'fa fa-pen text-white',
            command: () => {setModify(true)}
        },
        {
            label: 'Supprimer',
            icon: 'fa fa-trash-can text-white',
            command: () => {setDel(true)}
        }
    ];


    const toastload = useRef(null)
    const handleMod = (e) =>{
        e.preventDefault()
        if(labelM && descriptionM){
            toastload.current = toast.loading("Veuillez patienter...",{position: toast.POSITION?.TOP_LEFT})
            const formData = new FormData()

            formData.append('label', labelM)
            formData.append('description', descriptionM)
            formData.append('image', imageMp)

            biz.put(updateProductUrl+id, formData)
            .then(res =>{
                toast.update(toastload.current, {render: <span>Mise à jour éffectuée</span>,type: "success",isLoading: false, hideProgressBar: false})
                dispatch(fetchBussiness(bizId))
                window.location.reload()
            })
            .catch(err =>{
                toast.update(toastload.current, {render: err.response.data.name ?"Nom déjà existant de Biz'ness !":"Une erreur c'est produite", type: "error", isLoading: false, hideProgressBar: false, closeOnClick: true})
                console.log('err',err.response.data)
            })
        }else{
            toastload.current = toast.error("Remplir tout les champs", {position: toast.POSITION?.TOP_LEFT, isLoading: false, autoClose: false,hideProgressBar: false, closeOnClick: true})
        }
    }


    const handleDel = () =>{
        toastload.current = toast.loading("Veuillez patienter...",{position: toast.POSITION?.TOP_LEFT})

        biz.delete(deleteProductUrl+id,)
        .then(res =>{
            toast.update(toastload.current, {render: <span>Suppression éffectuée</span>,type: "success",isLoading: false, hideProgressBar: false})
            dispatch(fetchBussiness(bizId))
        })
        .catch(err =>{
            toast.update(toastload.current, {render: "Désolé, Une erreur c'est produite", type: "error", isLoading: false, hideProgressBar: false, closeOnClick: true})
            console.log('err',err.response.data)
        })
    }


    return (
        <>
            <div className="product-speeddial">
                <div className=' p-2 large-bizness-card rounded-3 mx-2 shadow-sm border' style={{width:'14rem'}} role='button' onClick={()=>setAdd(true)}>
                    <div className=' d-flex justify-content-center align-items-center rounded-3 overflow-hidden bg-body-secondary' style={{height:"20rem"}}>
                        <img alt={'image de' + label} src={baseURL + image} className=' object-fit-cover w-100 h-100' />
                    </div>
                    <span className=' mt-2 fs-6 fw-bold offer-card-description'>{label}</span>
                </div>
                {
                    (user && (user?.id == creatorId)) &&
                    <>
                        <Tooltip target=".speeddial-bottom-left .p-speeddial-action" position="left" />
                        <SpeedDial className='z-3 speeddial-bottom-left' buttonClassName=" btn rounded-circle p-2 border shadow-lg d-flex justify-content-center align-items-center bg-white" buttonStyle={{width:"2.7rem", height:"2.7rem"}} model={items} radius={120} transitionDelay={90} type="linear" direction="down" style={{ right: 9, top: 15 }} />
                    </>
                }
            </div>


            <Modal classNames={{modal:"w-100 bg-black bg-opacity-25"}} focusTrapped={false} closeIcon={<i className=' fa fa-close fs-3 text-white'></i>} center={true} open={add} onClose={()=>setAdd(false)} >
                <div className=' row align-items-start justify-content-between'>
                    <div className=' col-md-6 col-12'>
                        <div className=' w-100'>
                            <img alt={label} src={baseURL+image} className='rounded-2 img-fluid' style={{maxHeight:"70vh"}} />
                        </div>
                    </div>
                    <div className=' col-md-6 col-12 text-start text-md-center mt-md-0 mt-3'>
                        <h2 className=' color-primary fw-bold'> {label} </h2>
                        <p className=' text-white fst-italic'> {description} </p>
                    </div>
                </div>
            </Modal>


            <Modal center={true} open={modify} onClose={()=>setModify(false)} >
                <div className=' p-md-4 p-1'>
                    <h4 className=' fs-3 fw-bold px-md-4 px-2'>Modifier l'offre {label} </h4>
                    <div className=' bg-white px-3 py-4 text-center w-100 my-3' style={{maxWidth:'900px'}}>
                        <div className=' mx-auto'>

                            <form>
                                <div className=' d-flex align-items-start justify-content-start flex-wrap'>
                                    <div className='inputFile mb-4 text-start'>
                                        <span className=' text-black-50 fs-6 fw-medium ms-2'>Photo</span>
                                        <label role='button' htmlFor='image' className='mt-2 d-flex flex-column justify-content-center align-items-center bg-body-secondary rounded-4 mx-auto overflow-hidden' style={{width:"7rem",height:"10rem"}}>
                                            {imageMp?<img alt='' src={URL.createObjectURL(imageMp)} className='object-fit-cover w-100 h-100 border rounded-4' />:<img alt='' src={baseURL + image} className='object-fit-cover w-100 h-100 border rounded-4' />}
                                        </label>
                                        <input name='image' id='image' onChange={(e)=>{setImageMp(e.target.files[0])}} type='file' accept='image/*' max-file-size="1331200" className='w-100 d-none'/>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-center align-items-center pb-1 border-bottom border-1 border-black'>
                                    <label htmlFor='label'  className='px-2 py-1 bgcolor-primary d-flex justify-content-center align-items-center ms-2'>
                                        <i className='ri-user-3-line text-white'></i><RedStar/>
                                    </label>
                                    <input type='text' value={labelM} onChange={(e)=>setLabelM(e.target.value)} id='label' placeholder="Libellé" className='form-control border-0'/>
                                </div>
                                <div className='d-flex justify-content-center align-items-start pb-1 border-bottom border-1 border-black mt-4'>
                                    <label htmlFor='description' className='px-2 py-1 bgcolor-primary d-flex justify-content-center align-items-center ms-2'>
                                        <i className=' ri-message-2-line text-white'></i><RedStar/>
                                    </label>
                                    <textarea value={descriptionM} onChange={(e)=>setDescriptionM(e.target.value)} id='description' placeholder="Bio, à propos de vous" className='form-control border-0' style={{minHeight:"6rem"}}></textarea>
                                </div>

                                <input type='submit' value={"Modifier l'Offre"} onClick={handleMod} className=' mt-3 py-2 border-0 bgcolor-primary w-100 text-white fw-bold'/>
                            </form>
                        </div>
                    </div>
                </div>
            </Modal>


            <Modal center={true} open={del} onClose={()=>setDel(false)} >
                <div className='p-md-2 p-1 text-start'>
                    <h4 className='fw-bold pe-md-3 fs-4'>Supprimer l'offre {label} </h4>
                    <div className=' d-flex justify-content-start align-items-center mt-2'>
                        <i className=' fa fa-triangle-exclamation text-danger fa-3x'></i>
                        <p className=' ms-2 mb-0'>Êtes-vous sûr de vouloir supprimer cette offre ?</p>
                    </div>
                    <div className=' d-flex justify-content-end align-items-center'>
                        <button onClick={()=>setDel(false)} className=' btn border py-1 bordercolor-primary'>Annuler</button>
                        <button onClick={()=>handleDel()} className=' border-0 py-1 px-2 none bgcolor-primary rounded text-white ms-1'>Confirmer</button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ProductCard