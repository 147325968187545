import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import biz from "../../api/axiosConfig";
import { loginUserUrl } from "../../api/enpoint";


//Action
export const loginUser = createAsyncThunk('loginUser', async (data)=>{
    const resp = await biz.post(loginUserUrl, data)
    return resp.data;
})


const loginUserSlice = createSlice({
    name:'loginUser',
    initialState:{
        loadLoginUser: false,
        loginUser: null,
        errLoginUser: null,
    },
    extraReducers: (builder) =>{
        builder
        .addCase(loginUser.pending, (state, action) =>{
            state.loadLoginUser = true
            state.loginUser = null
            state.errLoginUser = null
        })
        .addCase(loginUser.fulfilled, (state, action) =>{
            state.loadLoginUser = false
            state.loginUser = action.payload
            state.errLoginUser = null
        })
        .addCase(loginUser.rejected, (state, action) =>{
            state.loadLoginUser = false
            if (action.error.message === "Request failed with status code 401") {
                state.errLoginUser = 'Access Denied'
            } else {
                state.errLoginUser = action.error.message
            }
        })
    }
})

export default loginUserSlice.reducer;