import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchBussinesses } from '../redux/slices/bussinessGetAll'
import ErrorRelod from '../components/ErrorRelod'
import LargeBiznessCardPlaceholder from '../components/LargeBiznessCardPlaceholder'
import LargeBiznessCard from '../components/LargeBiznessCard'
import { ToastContainer } from 'react-toastify'
import { baseURL } from '../api/enpoint'
import { fetchUserFavorite } from '../redux/slices/likedUser'
import { ratingsAnalyz } from '../constants/functions'

const FavoriteList = () => {

    const dispatch = useDispatch()
    const { favoriteU, loadFavoriteU, errFavoriteU } = useSelector(state => state.favoriteU)
    const { user } = useSelector(state => state.user)

    useEffect(()=>{
        dispatch(fetchUserFavorite(user.id))
    },[dispatch,user])


    return (
        <div>
            {
                loadFavoriteU?
                    <div className=' row justify-content-start align-items-center'>
                        {
                            [1,2,3,4,5].map((elt,index) =>(
                                <div key={index+elt} className=' col-lg-4 col-md-6 col-12'>
                                    <LargeBiznessCardPlaceholder/>
                                </div>
                            ))
                        }
                    </div>
                :
                errFavoriteU?
                    <ErrorRelod onclick={()=>dispatch(fetchBussinesses())} />
                :
                favoriteU?.length  <= 0?
                    <div className=' d-flex justify-content-center align-items-center flex-column' style={{minHeight:"20rem"}}>
                        <span className=' d-block fst-italic mb-4 fs-5'>Aucun Biz'ness favorie</span>
                    </div>
                :favoriteU?
                    <div className=' row justify-content-start align-items-start'>
                        {
                            favoriteU.map((elt,index) =>(
                                <div key={index} className=' col-lg-4 col-md-6 col-12'>
                                    <LargeBiznessCard cover={baseURL+elt.bussiness.photo} domain={elt.bussiness.domain.label} location={elt.bussiness.location} logo={baseURL+elt.bussiness.icon} name={elt.bussiness.name} rating={ratingsAnalyz(elt.bussiness?.comment).moy} bizId={elt.bussiness.id}/>
                                </div>
                            ))
                        }
                    </div>
                :null
            }


            <ToastContainer pauseOnFocusLoss={false} hideProgressBar={true}/>
        </div>
    )
}

export default FavoriteList