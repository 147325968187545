import React, { useEffect } from 'react'
import LargeBiznessCard from '../components/LargeBiznessCard'
import ProductCard from '../components/ProductCard'
import { fetchBussinesses } from '../redux/slices/bussinessGetAll'
import { useDispatch, useSelector } from 'react-redux'
import { baseURL } from '../api/enpoint'
import ErrorRelod from '../components/ErrorRelod'
import LargeBiznessCardPlaceholder from '../components/LargeBiznessCardPlaceholder'


const Home = () => {

    const dispatch = useDispatch()
    useEffect(()=>{
        dispatch(fetchBussinesses())
    },[dispatch])

    const { bussinesses, loadBussinesses, errGetBussinesses } = useSelector(state => state.bussinesses)

    return (
        <div className=' mt-5 pt-5'>
            <div className=' container'>

                <section>
                    <h2 className=' color-secondary fw-bold'>Biz'ness près de chez vous</h2>
                    <div>
                        {
                            loadBussinesses?
                                <div className=' row justify-content-start align-items-center'>
                                    {
                                        [1,2,3,4,5].map((elt,index) =>(
                                            <div key={index+elt} className=' col-lg-4 col-md-6 col-12'>
                                                <LargeBiznessCardPlaceholder/>
                                            </div>
                                        ))
                                    }
                                </div>
                            :
                            errGetBussinesses?
                                <ErrorRelod onclick={()=>dispatch(fetchBussinesses())} />
                            :
                            bussinesses?.length  <= 0?
                                <div className=' d-flex justify-content-center align-items-center flex-column' style={{minHeight:"20rem"}}>
                                    <span className=' d-block fst-italic mb-4 fs-5'>Aucun Biz'ness trouvé</span>
                                </div>
                            :bussinesses?
                                <div className=' row justify-content-start align-items-start'>
                                    {
                                        bussinesses.map((elt,index) =>(
                                            <div key={index} className=' col-lg-4 col-md-6 col-12'>
                                                <LargeBiznessCard cover={baseURL+elt.photo} domain={elt.domain.label} location={elt.location} logo={baseURL+elt.icon} name={elt.name} rating={4} bizId={elt.id} creatorId={elt.created_by} />
                                            </div>
                                        ))
                                    }
                                </div>
                            :null
                        }
                    </div>
                </section>

                <section className=' mt-5 mb-3'>
                    <h2 className=' color-secondary fw-bold'>Offres qui pourraient vous intéréssée</h2>
                    <div className=' row justify-content-center align-items-center'>
                        <div className=' col-lg-3 col-md-6 col-12'>
                            <ProductCard/>
                        </div>
                        <div className=' col-lg-3 col-md-6 col-12'>
                            <ProductCard/>
                        </div>
                        <div className=' col-lg-3 col-md-6 col-12'>
                            <ProductCard/>
                        </div>
                        <div className=' col-lg-3 col-md-6 col-12'>
                            <ProductCard/>
                        </div>
                        <div className=' col-lg-4 col-md-6 col-12'>
                            <ProductCard/>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Home
