import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import biz from "../../api/axiosConfig";
import { verifyLikedBussinessUrl } from "../../api/enpoint";


//Action
export const fetchBussinessFavorite = createAsyncThunk('fetchBussinessFavorite', async (id)=>{
    const resp = await biz.get(verifyLikedBussinessUrl+id)
    return resp.data;
})


const likedBussinessSlice = createSlice({
    name:'fetchBussinessFavorite',
    initialState:{
        loadFavorite: false,
        favorite: null,
        errFavorite: null,
    },
    extraReducers: (builder) =>{
        builder
        .addCase(fetchBussinessFavorite.pending, (state, action) =>{
            state.loadFavorite = true
            state.favorite = null
            state.errFavorite = null
        })
        .addCase(fetchBussinessFavorite.fulfilled, (state, action) =>{
            state.loadFavorite = false
            state.favorite = action.payload
            state.errFavorite = null
        })
        .addCase(fetchBussinessFavorite.rejected, (state, action) =>{
            state.loadFavorite = false
            if (action.error.message === "Request failed with status code 401") {
                state.errFavorite = 'Access Denied'
            } else {
                state.errFavorite = action.error.message
            }
        })
    }
})

export default likedBussinessSlice.reducer;