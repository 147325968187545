import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import biz from "../../api/axiosConfig";
import { getDomainsURL } from "../../api/enpoint";


//Action
export const fetchDomains = createAsyncThunk('fetchDomains', async (data)=>{
    const resp = await biz.get(getDomainsURL, data)
    return resp.data;
})


const getDomainsSlice = createSlice({
    name:'fetchDomains',
    initialState:{
        loadDomains: false,
        domains: null,
        errGetDomains: null,
    },
    extraReducers: (builder) =>{
        builder
        .addCase(fetchDomains.pending, (state, action) =>{
            state.loadDomains = true
            state.domains = null
            state.errGetDomains = null
        })
        .addCase(fetchDomains.fulfilled, (state, action) =>{
            state.loadDomains = false
            state.domains = action.payload
            state.errGetDomains = null
        })
        .addCase(fetchDomains.rejected, (state, action) =>{
            state.loadDomains = false
            if (action.error.message === "Request failed with status code 401") {
                state.errGetDomains = 'Access Denied'
            } else {
                state.errGetDomains = action.error.message
            }
        })
    }
})

export default getDomainsSlice.reducer;