import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import biz from '../api/axiosConfig'
import { checkUserPasswordUrl, deleteUserAccountUrl } from '../api/enpoint'
import Modal from 'react-responsive-modal'
import { useNavigate } from 'react-router-dom'
import { fetchUser } from '../redux/slices/userGet'
import Cookies from 'js-cookie';

const DeleteAccount = () => {

    const[open, setOpen]=useState(false)
    const[mdp, setMdp] = useState('')

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { user } = useSelector(state => state.user)

    const toastload = useRef(null)
    const handleLog = (e) =>{
        e.preventDefault()
        if(mdp){
            toastload.current = toast.loading("Veuillez patienter...",{position: toast.POSITION?.TOP_LEFT})
            
            biz.post(checkUserPasswordUrl, {password: mdp})
            .then((res)=>{
                if (!res.data){
                    toast.update(toastload.current, {render:"Mot de passe incorrect", type: "error", isLoading: false, hideProgressBar: false, closeOnClick: true})
                }else{
                    biz.put(deleteUserAccountUrl+user.id)
                    .then(res =>{
                        toast.update(toastload.current, {render: <span>Compte supprimé</span>,type: "success",isLoading: false, hideProgressBar: false})
                        Cookies.remove('token')
                        dispatch(fetchUser())
                        navigate('/')
                        window.location.reload()
                    })
                    .catch(err =>{
                        toast.update(toastload.current, {render: "Une erreur c'est produite", type: "error", isLoading: false, hideProgressBar: false, closeOnClick: true})
                        console.log('err',err.response.data)
                    })
                }
            })
            .catch(()=>{
                toast.update(toastload.current, {render:"Une erreur c'est produite", type: "error", isLoading: false, hideProgressBar: false, closeOnClick: true})
            })
        }else{
            toastload.current = toast.error("Remplir tout les champs", {position: toast.POSITION?.TOP_LEFT, isLoading: false, autoClose: false,hideProgressBar: false, closeOnClick: true})
        }
    }


    
    return (
        <div className=' d-flex justify-content-center align-items-center flex-column mx-auto text-center' style={{minHeight:"300px", maxWidth:"400px"}}>
            <h4 className=' fs-3 text-danger fw-bold'>Suppression de compte</h4>
            <span>Notez que cette action est irréversible et entraine la suppression de tout les biz'ness leurs services relié à votre compte </span>
            <div onClick={()=>setOpen(true)} role='button' className=' bg-danger text-white fw-bold rounded-3 px-4 py-2 mt-4'>Supprimer</div>


            <Modal open={open} onClose={()=>setOpen(false)} center >
                <div className=' px-3 py-2'>
                    <span className=' fs-5 fw-bold fst-italic'>Entrez votre mot de passe pour valider</span>
                    <div className='d-flex justify-content-center align-items-center pb-1 border-bottom border-1 border-black mt-4'>
                        <label htmlFor='mdp'  className='px-2 py-1 bgcolor-primary d-flex justify-content-center align-items-center ms-2'>
                            <i className='ri-lock-2-line text-white'></i>
                        </label>
                        <input type='password' value={mdp} onChange={(e)=>setMdp(e.target.value)} id='mdp' placeholder="Nouveau mot de passe" className='form-control border-0'/>
                    </div>
                    <input type='submit' value={'Supprimer définitivement'} onClick={handleLog} className=' py-2 px-3 mt-4 border-0 bg-danger w-100 text-white fw-bold'/>
                </div>
            </Modal>


            <ToastContainer pauseOnFocusLoss={false} hideProgressBar={true}/>
        </div>
    )
}

export default DeleteAccount