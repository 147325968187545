/* eslint-disable eqeqeq */
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { fetchBussiness } from '../redux/slices/bussinessGetOne'
import ErrorRelod from '../components/ErrorRelod'
import { baseURL, createProductUrl, updateBussinessUrl } from '../api/enpoint'
import CustomButtons from '../components/CustomButtons'
import ProductCard from '../components/ProductCard'
import RedStar from '../components/RedStar'
import Modal from 'react-responsive-modal'
import { ToastContainer, toast } from 'react-toastify'
import biz from '../api/axiosConfig'
import Carousel from 'react-multi-carousel'
import Select from 'react-select';
import { PhoneInput } from 'react-international-phone'
import { fetchDomains } from '../redux/slices/domainsGet'
import RateForm from '../components/RateForm'
import RateCard from '../components/RateCard'
import { likeBussiness } from '../redux/slices/likeBussiness'
import { unLikeBussiness } from '../redux/slices/unLikeBussiness'
import { fetchBussinessFavorite } from '../redux/slices/likedBussiness'
import DateObject from 'react-date-object'
import BiznessPlaceholder from '../components/BiznessPlaceholder'

const Bizness = () => {

    const dispatch = useDispatch()
    const {bizId} = useParams()

    const {loadBussiness, bussiness, errGetBussiness} =useSelector(state => state.bussiness)
    const {domains} = useSelector(state => state.domains)
    const { user } = useSelector(state => state.user)
    const { favorite, loadFavorite } = useSelector(state => state.favorite)
    
    const[add, setAdd] = useState(false)
    const[label, setLabel] = useState('')
    const[description, setDescription] = useState('')
    const[image, setImage] = useState()


    const[modify, setModify] = useState(false)
    const[name, setName] = useState('')
    const[descriptionB, setDescriptionB] = useState('')
    const[phone, setPhone] = useState('')
    const[email, setEmail] = useState('')
    const[photo, setPhoto] = useState()
    const[photoP, setPhotoP] = useState()
    const[icon, setIcon] = useState()
    const[iconP, setIconP] = useState()
    const[creation, setCreation] = useState('')
    const[location, setLocation] = useState('Douala')
    const[website, setWebsite] = useState('')
    const[facebook, setFacebook] = useState('')
    const[instagram, setInstagram] = useState('')
    const[linkedin, setLinkedin] = useState('')
    const[twitter, setTwitter] = useState('')
    const[domain, setDomain] = useState('')

    
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 9
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1.5
        }
    };

    useEffect(()=>{
        dispatch(fetchBussiness(bizId))
        dispatch(fetchBussinessFavorite(bizId))
    },[dispatch,bizId])

    const[data, setData]=useState([{}])
    useEffect(()=>{
        dispatch(fetchDomains())
    },[dispatch])

    useEffect(()=>{
        if (domains) {
            domains.map((elt) =>( data.push({label: elt.label, value: elt.id})))
            setData(data)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[domains])

    useEffect(()=>{
        if (bussiness) {
            const date = new DateObject(bussiness?.creation)
            setName(bussiness?.name)
            setDescriptionB(bussiness?.description)
            setPhone(bussiness?.telephone)
            setEmail(bussiness?.email)
            setPhoto(bussiness?.photo)
            setIcon(bussiness?.icon)
            setCreation(date.format("YYYY-MM-DD"))
            setLocation(bussiness?.location)
            setWebsite(bussiness?.website)
            setFacebook(bussiness?.facebook)
            setInstagram(bussiness?.instagram)
            setLinkedin(bussiness?.linkedin)
            setTwitter(bussiness?.twitter)
            setDomain(data.find(elt => elt.value === bussiness?.domain_id))
        }
    },[bussiness, data])

    const formatDateTime = (elt) =>{
        const date = new Date(elt)

        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        };
        
        const formatter = new Intl.DateTimeFormat('fr-FR', options);
        const formattedDate = formatter.format(date);
        return formattedDate
    }


    const toastload = useRef(null)
    const handleLog = (e) =>{
        e.preventDefault()
        if(label && description && image){
            toastload.current = toast.loading("Veuillez patienter...",{position: toast.POSITION?.TOP_LEFT})
            const formData = new FormData()

            formData.append('image', image)
            formData.append('bussiness_id', bussiness?.id)
            formData.append('label', label)
            formData.append('description', description)

            biz.post(createProductUrl, formData)
            .then(res =>{
                toast.update(toastload.current, {render: <span>Offre ajoutée</span>,type: "success",isLoading: false, hideProgressBar: false})
                dispatch(fetchBussiness(bizId))
                setDescription('')
                setImage()
                setLabel('')
                setAdd(false)
            })
            .catch(err =>{
                toast.update(toastload.current, {render: err.response.data.data ?"Remplir tout les champs obligatoires":"Une erreur c'est produite", type: "error", isLoading: false, hideProgressBar: false, closeOnClick: true})
                console.log('err',err.response.data)
            })
        }else{
            toastload.current = toast.error("Remplir tout les champs", {position: toast.POSITION?.TOP_LEFT, isLoading: false, autoClose: false,hideProgressBar: false, closeOnClick: true})
        }
    }

    const handleModify = (e) =>{
        e.preventDefault()
        if(name && email && phone && location){
            toastload.current = toast.loading("Veuillez patienter...",{position: toast.POSITION?.TOP_LEFT})
            const formData = new FormData()

            formData.append('icon', iconP)
            formData.append('photo', photoP)
            formData.append('name', name)
            formData.append('domain_id', domain?.value)
            formData.append('email', email)
            formData.append('telephone', phone)
            formData.append('creation', creation)
            formData.append('website', website)
            formData.append('facebook', facebook)
            formData.append('instagram', instagram)
            formData.append('twitter', twitter)
            formData.append('linkedin', linkedin)
            formData.append('description', descriptionB)
            formData.append('location', location)

            biz.put(updateBussinessUrl+bussiness?.id, formData)
            .then(res =>{
                toast.update(toastload.current, {render: <span>Mise à jour éffectuée</span>,type: "success",isLoading: false, hideProgressBar: false})
                fetchBussiness(bizId)
                window.location.reload()
            })
            .catch(err =>{
                toast.update(toastload.current, {render: err.response.data.name ?"Nom déjà existant de Biz'ness !":"Une erreur c'est produite", type: "error", isLoading: false, hideProgressBar: false, closeOnClick: true})
                console.log('err',err.response.data)
            })
        }else{
            toastload.current = toast.error("Remplir tout les champs", {position: toast.POSITION?.TOP_LEFT, isLoading: false, autoClose: false,hideProgressBar: false, closeOnClick: true})
        }
    }



    // Rating part
    const[verifyRating, setVerifyRating] = useState(false)
    const[ratings, setRatings] = useState({ moy: 0, sum: 0, 5: 0, 4: 0, 3: 0, 2: 0, 1: 0 })

    useEffect(() => {
        if (user && bussiness) {
            let verif = bussiness?.comment?.find(elt => elt.id === user.id)
            if (verif) {
                setVerifyRating(false)
            }else{
                setVerifyRating(true)
            }
        }else{
            setVerifyRating(false)
        }
    }, [bussiness, user])

    useEffect(() => {
        if (bussiness) {
            setRatings(
                bussiness.comment.reduce((acc, item) => {
                    acc.moy = ((acc.moy * acc.sum + item.rating) / (acc.sum + 1)).toFixed(1);
                    acc.sum++;
                    acc[item.rating]++;
                    return acc;
                }, { moy: 0, sum: 0, 5: 0, 4: 0, 3: 0, 2: 0, 1: 0 })
            )
        }
    }, [bussiness])




    // Like Bussiness Part
    const[verifyLk, setVerifyLk] = useState(false)

    useEffect(() => {
        if (favorite && user) {
            // eslint-disable-next-line eqeqeq
            let verify = favorite.find(elt => ((elt.user_id == user.id) && (elt.bussiness_id == bizId)))
            if (verify) {
                setVerifyLk(true)
            } else {
                setVerifyLk(false)
            }
        }
    }, [favorite, bizId, user]);

    const handleLikeB = () =>{
        // eslint-disable-next-line eqeqeq
        let verify = favorite?.find(elt => ((elt.user_id == user?.id) && (elt.bussiness_id == bizId)))
        toastload.current = toast.loading("Veuillez patienter...",{position: toast.POSITION?.TOP_LEFT})
        if (verify) {
            dispatch(unLikeBussiness(bizId))
            .then(res =>{
                if (res.payload) {
                    dispatch(fetchBussinessFavorite(bizId))
                    toast.update(toastload.current, {render: <span>Vous avez aimé</span>,type: "success",isLoading: false, hideProgressBar: false})
                }else{
                    toast.update(toastload.current, {render: "Une erreur c'est produite", type: "error", isLoading: false, hideProgressBar: false, closeOnClick: true})
                }
            })
        } else {
            dispatch(likeBussiness(bizId))
            .then(res =>{
                if (res.payload) {
                    dispatch(fetchBussinessFavorite(bizId))
                    toast.update(toastload.current, {render: <span>Vous avez aimé</span>,type: "success",isLoading: false, hideProgressBar: false})
                }else{
                    toast.update(toastload.current, {render: "Une erreur c'est produite", type: "error", isLoading: false, hideProgressBar: false, closeOnClick: true})
                }
            })
        }
    }


    return (
        <>
            {
                loadBussiness?
                    <BiznessPlaceholder/>
                :
                errGetBussiness?
                    <ErrorRelod onclick={()=>dispatch(fetchBussiness(bizId))} />
                :bussiness?
                    <div className=' position-relative'>
                        {/* Hero */}
                        <div className=' position-relative' style={{minHeight:"70vh", maxHeight:"92vh"}}>
                            <div className=' w-100 h-100 bg-black d-flex justify-content-center align-items-center overflow-hidden' style={{minHeight:"70vh", maxHeight:"92vh"}}>
                                <img alt={bussiness.name} src={baseURL + bussiness.photo} className='object-fit-cover w-100 h-100' />
                            </div>
                            <div className=' biz-hero-overlay'>
                                <div className=' container h-100 z-2'>
                                    <div style={{maxWidth:"650px"}} className=' d-flex justify-content-end flex-column h-100 py-5 text-white'>
                                        <div  className=' mb-4'>
                                            <h1 className=' fw-bold fa-3x mb-3'>{bussiness.name}</h1>
                                            <span className=' color-primary fw-medium fs-5 d-block'> {bussiness.domain.label} </span>
                                            <small className=' text-white-50 text-capitalize'> secteur {bussiness.domain.sector} </small>
                                        </div>
                                        <div className=' row gx-md-5 flex-wrap align-items-center'>
                                            <div className=' col-auto'>
                                                <div className=' d-flex justify-content-center align-items-center rounded-3 overflow-hidden' style={{width:'4.5rem',height:'4.5rem'}}>
                                                    <img alt={bussiness.icon} src={baseURL + bussiness.icon} className=' object-fit-cover w-100 h-100' />
                                                </div>
                                            </div>
                                            <div className='col-auto d-flex flex-column align-items-center'>
                                                <span className=' d-flex align-items-center'>{ratings.moy} <i className=' fa fa-star fs-8s'></i></span>
                                                <small className=' text-white-50'>{ratings.sum} avis</small>
                                            </div>
                                            <div className='col-auto d-flex flex-column align-items-center'>
                                                <span className=' d-flex align-items-center'>{favorite?favorite.length:0} <i className=' fa fa-heart ms-1 fs-8s'></i></span>
                                                <small className=' text-white-50'>Adore</small>
                                            </div>
                                        </div>
                                        <div className=' mt-4 row flex-row align-items-center'>
                                            <div className=' col'>
                                                <CustomButtons text={"Direction"} icon_left={"fa  fa-route"} />
                                            </div>
                                            <div className=' col-auto' role='button' onClick={()=>handleLikeB()}>
                                                <i className={'fa-2x ' + (verifyLk && !loadFavorite?'ri-heart-3-fill text-danger':'ri-heart-3-line text-white')}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Content */}
                        <div className=' container mt-md-5 mt-4'>
                            <section>
                                <h3 className=' fs-4 mb-3 fw-bold color-secondary'> Nos offres de {bussiness.name} </h3>
                                {!bussiness.product ?
                                    <>
                                    <span className=' fst-italic'>Aucune offre disponible</span>
                                    {
                                        (user?.id == bussiness.created_by) &&
                                    <div role='button' onClick={()=>setAdd(true)} className=" d-flex justify-content-center align-items-center px-3 py-2 bg-dark-subtle rounded-3 text-white m-2" style={{width:'13rem', height:"20rem"}}>
                                        <i className='me-2 fs-2 fa fa-add text-black'></i>
                                    </div>
                                    }
                                    </>:
                                    <div className=' d-flex'>
                                        {/* btn add product */}
                                        <Carousel responsive={responsive} showDots={false} swipeable className='w-100 product-carousel'>
                                            {
                                                (user?.id == bussiness.created_by) &&
                                            <div role='button' onClick={()=>setAdd(true)} className=" d-flex justify-content-center align-items-center px-3 py-2 bg-dark-subtle rounded-3 text-white m-2" style={{width:'13rem', height:"20rem"}}>
                                                <i className='me-2 fs-2 fa fa-add text-black'></i>
                                            </div>
                                            }
                                            {
                                                bussiness.product.map((elt,index) =>(
                                                    <ProductCard image={elt.image} label={elt.label} description={elt.description} bizId={bizId} id={elt.id} creatorId={bussiness.created_by} key={index}/>
                                                ))
                                            }
                                        </Carousel>
                                    </div>
                                }
                            </section>

                            <section className=' mt-5'>
                                <h3 className=' fs-4 mb-3 fw-bold color-secondary'> Description de {bussiness.name} </h3>
                                <p className=' text-black text-opacity-75'> {bussiness.description} </p>
                            </section>

                            <section className=' mt-5'>
                                <h4 className=' fs-5 mb-2 fw-medium color-secondary'>Dernière mise à jour</h4>
                                <span className=' text-capitalize'> {formatDateTime(bussiness.updatedAt)} </span>
                            </section>

                            <section className=' mt-5'>
                                <h3 className=' fs-4 mb-3 fw-bold color-secondary'> Contacts </h3>
                                <div className=' border rounded-3 p-3 d-inline-block'>
                                    <a href={'tel:'+bussiness.telephone} className=' d-flex align-items-center text-black mb-1'>
                                        <i className='ri-phone-line text-black-50 me-2 fs-5'></i>
                                        {bussiness.telephone}
                                    </a>
                                    <a href={'mailto:'+bussiness.email} className=' d-flex align-items-center text-black mb-1'>
                                        <i className='ri-mail-line text-black-50 me-2 fs-5'></i>
                                        {bussiness.email}
                                    </a>
                                    {
                                        bussiness.website &&
                                        <a href={bussiness.website} target='_blank' rel='noreferrer' className=' d-flex align-items-center text-black mb-1 text-one-line'>
                                            <i className='ri-link-m text-black-50 me-2 fs-5'></i>
                                            <span className='text-one-line'>{bussiness.website}</span>
                                        </a>
                                    }
                                    {
                                        bussiness.facebook &&
                                        <a href={bussiness.facebook} target='_blank' rel='noreferrer' className=' d-flex align-items-center text-black mb-1'>
                                            <i className='ri-facebook-circle-fill text-black-50 me-2 fs-5'></i>
                                            <span className='text-one-line'>{bussiness.facebook}</span>
                                        </a>
                                    }
                                    {
                                        bussiness.instagram &&
                                        <a href={bussiness.instagram} target='_blank' rel='noreferrer' className=' d-flex align-items-center text-black mb-1'>
                                            <i className='ri-instagram-fill text-black-50 me-2 fs-5'></i>
                                            <span className='text-one-line'>{bussiness.instagram}</span>
                                        </a>
                                    }
                                    {
                                        bussiness.linkedin &&
                                        <a href={bussiness.linkedin} target='_blank' rel='noreferrer' className=' d-flex align-items-center text-black mb-1'>
                                            <i className='ri-linkedin-fill text-black-50 me-2 fs-5'></i>
                                            <span className='text-one-line'>{bussiness.linkedin}</span>
                                        </a>
                                    }
                                    {
                                        bussiness.twitter &&
                                        <a href={bussiness.twitter} target='_blank' rel='noreferrer' className=' d-flex align-items-center text-black mb-1'>
                                            <i className='ri-twitter-x-fill text-black-50 me-2 fs-5'></i>
                                            <span className='text-one-line'>{bussiness.twitter}</span>
                                        </a>
                                    }
                                </div>
                            </section>

                            {
                                verifyRating &&
                                <section className=' mt-5'>
                                    <h3 className=' fs-4 mb-3 fw-bold color-secondary'> Laissez votre avis </h3>
                                    <RateForm add={true} bizId={bizId}/>
                                </section>
                            }

                            <section className=' mt-5'>
                                <h3 className=' fs-4 mb-3 fw-bold color-secondary'> Notes et avis </h3>
                                <div style={{maxWidth:'800px'}}>
                                    <div className=' row justify-content-start align-items-center gx-md-4'>
                                        <div className='col-auto'>
                                            <h5 className=' fw-bold mb-0' style={{fontSize:"3.5rem"}}>{ratings.moy}</h5>
                                            <div className=' d-flex mx-auto'>
                                                {Array(Math.floor(ratings.moy)).fill(<i className=' fa fa-star color-primary fs-6s'></i>)}
                                                {Array(Math.floor(5 - ratings.moy)).fill(<i className=' fa fa-star text-secondary fs-6s'></i>)}
                                            </div>
                                            <span className=' text-black-50 mt-1 d-block'>{ratings.sum} avis</span>
                                        </div>
                                        <div className=' col'>
                                            <div className=' d-flex align-items-center'>
                                                <span className='pe-lg-2 pe-1 text-black text-opacity-75 fs-9s'>5</span>
                                                <div className="progress w-100" role="progressbar" style={{height:"10px"}}>
                                                    <div title={ratings[5]} className="progress-bar bgcolor-primary rounded" style={{width: ((ratings[5]*100)/ratings.sum)+"%"}}></div>
                                                </div>
                                            </div>
                                            <div className=' d-flex align-items-center'>
                                                <span className='pe-lg-2 pe-1 text-black text-opacity-75 fs-9s'>4</span>
                                                <div className="progress w-100" role="progressbar" style={{height:"10px"}}>
                                                    <div title={ratings[4]} className="progress-bar bgcolor-primary rounded" style={{width: ((ratings[4]*100)/ratings.sum)+"%"}}></div>
                                                </div>
                                            </div>
                                            <div className=' d-flex align-items-center'>
                                                <span className='pe-lg-2 pe-1 text-black text-opacity-75 fs-9s'>3</span>
                                                <div className="progress w-100" role="progressbar" style={{height:"10px"}}>
                                                    <div title={ratings[3]} className="progress-bar bgcolor-primary rounded" style={{width: ((ratings[3]*100)/ratings.sum)+"%"}}></div>
                                                </div>
                                            </div>
                                            <div className=' d-flex align-items-center'>
                                                <span className='pe-lg-2 pe-1 text-black text-opacity-75 fs-9s'>2</span>
                                                <div className="progress w-100" role="progressbar" style={{height:"10px"}}>
                                                    <div title={ratings[2]} className="progress-bar bgcolor-primary rounded" style={{width: ((ratings[2]*100)/ratings.sum)+"%"}}></div>
                                                </div>
                                            </div>
                                            <div className=' d-flex align-items-center'>
                                                <span className='pe-lg-2 pe-1 text-black text-opacity-75 fs-9s'>1</span>
                                                <div className="progress w-100" role="progressbar" style={{height:"10px"}}>
                                                    <div title={ratings[1]} className="progress-bar bgcolor-primary rounded" style={{width: ((ratings[1]*100)/ratings.sum)+"%"}}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=' mt-4'>
                                        <div className='d-inline-block pt-4'>
                                            {
                                                bussiness.comment.map((elt,index) =>(
                                                    <RateCard photo={elt.user.photo} l_name={elt.user.l_name} rating={elt.rating} notice={elt.notice} createdAt={formatDateTime(elt.createdAt)} bizId={bizId} id={elt.id} creatorId={elt.user_id} key={index} />
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>


                        {/* BTN MODIFY */}
                        {
                            (user?.id === bussiness?.created_by) &&
                            <div className=' position-fixed color-primary rounded-circle bg-white shadow bottom-0 end-0 me-2 mb-3 border' style={{width:"3.2rem", height:"3.2rem"}} onClick={()=>setModify(true)}>
                                <div role='button' className=' w-100 h-100 d-flex justify-content-center align-items-center'>
                                    <i className='fa fa-pen-to-square fs-5 '></i>
                                </div>
                            </div>
                        }
                    </div>
                :null
            }



            <Modal center={true} open={add} onClose={()=>setAdd(false)} >
                <div className=' p-md-4 p-1'>
                    <h4 className=' fs-3 fw-bold px-md-4 px-2'>Ajoutez un produit à {bussiness?.name} </h4>
                    <div className=' bg-white px-3 py-4 text-center w-100 my-3' style={{maxWidth:'900px'}}>
                        <div className=' mx-auto'>

                            <form>
                                <div className=' d-flex align-items-start justify-content-start flex-wrap'>
                                    <div className='inputFile mb-4 text-start'>
                                        <span className=' text-black-50 fs-6 fw-medium ms-2'>Photo</span>
                                        <label role='button' htmlFor='image' className='mt-2 d-flex flex-column justify-content-center align-items-center bg-body-secondary rounded-4 mx-auto overflow-hidden' style={{width:"7rem",height:"10rem"}}>
                                            {!image?<i className=" ri-camera-line ri-3x opacity-50"></i>:<img alt='' src={URL.createObjectURL(image)} className='object-fit-cover w-100 h-100 border rounded-4' />}
                                        </label>
                                        <input name='image' id='image' onChange={(e)=>{setImage(e.target.files[0])}} type='file' accept='image/*' max-file-size="1331200" className='w-100 d-none'/>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-center align-items-center pb-1 border-bottom border-1 border-black'>
                                    <label htmlFor='label'  className='px-2 py-1 bgcolor-primary d-flex justify-content-center align-items-center ms-2'>
                                        <i className='ri-user-3-line text-white'></i><RedStar/>
                                    </label>
                                    <input type='text' value={label} onChange={(e)=>setLabel(e.target.value)} id='label' placeholder="Libellé" className='form-control border-0'/>
                                </div>
                                <div className='d-flex justify-content-center align-items-start pb-1 border-bottom border-1 border-black mt-4'>
                                    <label htmlFor='description' className='px-2 py-1 bgcolor-primary d-flex justify-content-center align-items-center ms-2'>
                                        <i className=' ri-message-2-line text-white'></i><RedStar/>
                                    </label>
                                    <textarea value={description} onChange={(e)=>setDescription(e.target.value)} id='description' placeholder="Bio, à propos de vous" className='form-control border-0' style={{minHeight:"6rem"}}></textarea>
                                </div>

                                <input type='submit' value={"Ajouter l'Offre"} onClick={handleLog} className=' mt-3 py-2 border-0 bgcolor-primary w-100 text-white fw-bold'/>
                            </form>
                        </div>
                    </div>
                </div>
            </Modal>


            <Modal center={true} open={modify} onClose={()=>{setModify(false); setIconP(); setPhotoP()}} >
                <div className=' p-md-4 p-1'>
                    <h4 className=' fs-3 fw-bold px-md-4 px-2'>Modifier les informations de {bussiness?.name} </h4>
                    <div className=' bg-white px-3 py-4 text-center w-100 my-3' style={{maxWidth:'900px'}}>
                        <div className=' mx-auto'>

                            <form>
                                <div className=' d-flex align-items-center justify-content-between flex-wrap'>
                                    <div className='inputFile mb-2'>
                                        <span className=' text-black-50 fs-6 fw-medium'>Logo <RedStar/></span>
                                        <label role='button' htmlFor='icon' className='mt-2 d-flex flex-column justify-content-center align-items-center bg-body-secondary rounded-circle mx-auto overflow-hidden border' style={{width:"5rem",height:"5rem"}}>
                                            {iconP?<img alt='' src={URL.createObjectURL(iconP)} className='object-fit-cover w-100 h-100' />:<img alt='' src={baseURL + icon} className='object-fit-cover w-100 h-100' />}
                                        </label>
                                        <input name='icon' id='icon' onChange={(e)=>{setIconP(e.target.files[0])}} type='file' accept='image/*' max-file-size="1331200" className='w-100 d-none'/>
                                    </div>
                                    <div className='inputFile mb-2'>
                                        <span className=' text-black-50 fs-6 fw-medium'>Photo de couverture</span>
                                        <label role='button' htmlFor='photo' className='mt-2 d-flex flex-column justify-content-center align-items-center bg-body-secondary rounded-4 mx-auto overflow-hidden border' style={{width:"8rem",height:"7rem"}}>
                                            {photoP?<img alt='' src={URL.createObjectURL(photoP)} className='object-fit-cover w-100 h-100' />:<img alt='' src={baseURL + photo} className='object-fit-cover w-100 h-100' />}
                                        </label>
                                        <input name='photo' id='photo' onChange={(e)=>{setPhotoP(e.target.files[0])}} type='file' accept='image/*' max-file-size="1331200" className='w-100 d-none'/>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-center align-items-center pb-1 border-bottom border-1 border-black'>
                                    <label htmlFor='name'  className='px-2 py-1 bgcolor-primary d-flex justify-content-center align-items-center ms-2'>
                                        <i className='ri-user-3-line text-white'></i><RedStar/>
                                    </label>
                                    <input type='text' value={name} onChange={(e)=>setName(e.target.value)} id='name' placeholder="Raison sociale" className='form-control border-0'/>
                                </div>
                                <div className='d-flex justify-content-center align-items-center pb-1 border-bottom border-1 border-black mt-4'>
                                    <label htmlFor='domain'  className='px-2 py-1 bgcolor-primary d-flex justify-content-center align-items-center ms-2'>
                                        <i className=' ri-building-line text-white'></i><RedStar/>
                                    </label>
                                    <Select
                                        value={domain}
                                        placeholder="Secteur"
                                        classNamePrefix =" border-0 "
                                        className=' border-0 w-100 text-start'
                                        onChange={setDomain}
                                        options={data}
                                    />
                                </div>
                                <div className='d-flex justify-content-center align-items-center pb-1 border-bottom border-1 border-black mt-4'>
                                    <label htmlFor='email'  className='px-2 py-1 bgcolor-primary d-flex justify-content-center align-items-center ms-2'>
                                        <i className=' ri-mail-line text-white'></i><RedStar/>
                                    </label>
                                    <input type='email' value={email} onChange={(e)=>setEmail(e.target.value)} id='email' placeholder="Adresse email" className='form-control border-0'/>
                                </div>
                                <div className='d-flex justify-content-center align-items-center pb-1 border-bottom border-1 border-black mt-4'>
                                    <label htmlFor='phone'  className='px-2 py-1 bgcolor-primary d-flex justify-content-center align-items-center ms-2'>
                                        <i className=' ri-phone-line text-white'></i><RedStar/>
                                    </label>
                                    <PhoneInput
                                        className=' w-100 border-0'
                                        defaultCountry="cm"
                                        value={phone}
                                        onChange={(phone) => setPhone(phone)}
                                    />
                                </div>
                                <div className='d-flex justify-content-center align-items-center pb-1 border-bottom border-1 border-black mt-4'>
                                    <label htmlFor='creation'  className='px-2 py-1 bgcolor-primary d-flex justify-content-center align-items-center ms-2'>
                                        <i className='ri-calendar-event-line text-white'></i><RedStar/>
                                    </label>
                                    <input type='date' value={creation} onChange={(e)=>setCreation(e.target.value)} id='creation' placeholder="Date de naissance" className='form-control border-0'/>
                                </div>
                                <div className='d-flex justify-content-center align-items-center pb-1 border-bottom border-1 border-black mt-4'>
                                    <label htmlFor='website'  className='px-2 py-1 bgcolor-primary d-flex justify-content-center align-items-center ms-2'>
                                        <i className='ri-global-line text-white'></i>
                                    </label>
                                    <input type='url' value={website} onChange={(e)=>setWebsite(e.target.value)} id='website' placeholder="Lien vers le website" className='form-control border-0'/>
                                </div>
                                <div className='d-flex justify-content-center align-items-center pb-1 border-bottom border-1 border-black mt-4'>
                                    <label htmlFor='facebook'  className='px-2 py-1 bgcolor-primary d-flex justify-content-center align-items-center ms-2'>
                                        <i className='ri-facebook-fill text-white'></i>
                                    </label>
                                    <input type='url' value={facebook} onChange={(e)=>setFacebook(e.target.value)} id='facebook' placeholder="Lien de la page facebook" className='form-control border-0'/>
                                </div>
                                <div className='d-flex justify-content-center align-items-center pb-1 border-bottom border-1 border-black mt-4'>
                                    <label htmlFor='instagram'  className='px-2 py-1 bgcolor-primary d-flex justify-content-center align-items-center ms-2'>
                                        <i className='ri-instagram-line text-white'></i>
                                    </label>
                                    <input type='url' value={instagram} onChange={(e)=>setInstagram(e.target.value)} id='instagram' placeholder="Lien de la page instagram" className='form-control border-0'/>
                                </div>
                                <div className='d-flex justify-content-center align-items-center pb-1 border-bottom border-1 border-black mt-4'>
                                    <label htmlFor='twitter'  className='px-2 py-1 bgcolor-primary d-flex justify-content-center align-items-center ms-2'>
                                        <i className='ri-twitter-x-line text-white'></i>
                                    </label>
                                    <input type='url' value={twitter} onChange={(e)=>setTwitter(e.target.value)} id='twitter' placeholder="Lien de la page twitter" className='form-control border-0'/>
                                </div>
                                <div className='d-flex justify-content-center align-items-center pb-1 border-bottom border-1 border-black mt-4'>
                                    <label htmlFor='linkedin'  className='px-2 py-1 bgcolor-primary d-flex justify-content-center align-items-center ms-2'>
                                        <i className='ri-linkedin-box-fill text-white'></i>
                                    </label>
                                    <input type='url' value={linkedin} onChange={(e)=>setLinkedin(e.target.value)} id='linkedin' placeholder="Lien de la page linkedin" className='form-control border-0'/>
                                </div>
                                <div className='d-flex justify-content-center align-items-start pb-1 border-bottom border-1 border-black mt-4'>
                                    <label htmlFor='description' className='px-2 py-1 bgcolor-primary d-flex justify-content-center align-items-center ms-2'>
                                        <i className=' ri-message-2-line text-white'></i><RedStar/>
                                    </label>
                                    <textarea value={descriptionB} onChange={(e)=>setDescriptionB(e.target.value)} id='description' placeholder="Bio, à propos de vous" className='form-control border-0' style={{minHeight:"6rem"}}></textarea>
                                </div>

                                <input type='submit' value={"Mettre à jour"} onClick={handleModify} className=' mt-3 py-2 border-0 bgcolor-primary w-100 text-white fw-bold'/>
                            </form>
                        </div>
                    </div>
                </div>
            </Modal>


            <ToastContainer pauseOnFocusLoss={false} hideProgressBar={true}/>
        </>
    )
}

export default Bizness