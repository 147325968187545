import React from 'react'
import ProductCardPlaceholder from './ProductCardPlaceholder';
import Carousel from 'react-multi-carousel';

const BiznessPlaceholder = () => {


    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 9
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1.5
        }
    };


    return (
        <div className=' placeholder-glow'>
            {/* Hero */}
            <div className='position-relative' style={{minHeight:"70vh", maxHeight:"92vh"}}>
                <div className=' biz-hero-overlay'>
                    <div className=' container h-100 z-2'>
                        <div style={{maxWidth:"650px"}} className=' d-flex justify-content-end flex-column h-100 py-5 text-white'>
                            <div  className=' mb-4'>
                                <h1 className=' fw-bold fa-3x mb-3 placeholder col-9'>.</h1>
                                <span className=' color-primary fw-medium fs-5 d-block placeholder col-7'> </span>
                                <small className=' text-white-50 text-capitalize  placeholder col-5'></small>
                            </div>
                            <div className=' row gx-md-5 flex-wrap align-items-center'>
                                <div className=' col-auto'>
                                    <div className=' d-flex justify-content-center align-items-center rounded-3 overflow-hidden' style={{width:'4.5rem',height:'4.5rem'}}>
                                        <img alt='' className=' object-fit-cover w-100 h-100  placeholder col-12' />
                                    </div>
                                </div>
                            </div>
                            <div className=' mt-4 ms-1 row flex-row align-items-center'>
                                <div className=' placeholder col-6 py-3'></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Content */}
            <div className=' container mt-md-5 mt-4'>
                <section>
                    <h3 className=' fs-4 mb-3 fw-bold color-secondary '>Offres</h3>
                    {
                        <div className=' d-flex'>
                            {/* btn add product */}
                            <Carousel responsive={responsive} showDots={false} swipeable className='w-100 product-carousel'>
                                {
                                    [1,1,1].map((elt,index) =>(
                                        <ProductCardPlaceholder key={index}/>
                                    ))
                                }
                            </Carousel>
                        </div>
                    }
                </section>

                <section className=' mt-5'>
                    <h3 className=' fs-4 mb-3 fw-bold color-secondary '>Description</h3>
                    <div style={{maxWidth:"600px"}}>
                        <p className=' text-black text-opacity-75 placeholder col-12'></p><br/>
                        <p className=' text-black text-opacity-75 placeholder col-11'></p><br/>
                        <p className=' text-black text-opacity-75 placeholder col-10'></p><br/>
                        <p className=' text-black text-opacity-75 placeholder col-11'></p>
                    </div>
                </section>

                <section className=' mt-5 mb-3'>
                    <h3 className=' fs-4 mb-3 fw-bold color-secondary'> Notes et avis </h3>
                    <div style={{maxWidth:'800px'}}>
                        <div className=' row justify-content-start align-items-center gx-md-4'>
                            <div className='col-auto'>
                                <h5 className=' fw-bold mb-0 placeholder col-12' style={{fontSize:"3.5rem"}}>{}</h5>
                                <div className=' d-flex mx-auto'>
                                    {Array(Math.floor(5)).fill(<i className=' fa fa-star color-primary fs-6s'></i>)}
                                    </div>
                                <span className=' text-black-50 mt-1 d-block placeholder col'></span>
                            </div>
                            <div className=' col'>
                                <div className=' d-flex align-items-center'>
                                    <span className='pe-lg-2 pe-1 text-black text-opacity-75 fs-9s'>5</span>
                                    <div className="progress w-100 placeholder col-12" role="progressbar" style={{height:"10px"}}>
                                        <div className="progress-bar bgcolor-primary rounded placeholder" style={{width:"0%"}}></div>
                                    </div>
                                </div>
                                <div className=' d-flex align-items-center'>
                                    <span className='pe-lg-2 pe-1 text-black text-opacity-75 fs-9s'>4</span>
                                    <div className="progress w-100 placeholder col-12" role="progressbar" style={{height:"10px"}}>
                                        <div className="progress-bar bgcolor-primary rounded placeholder" style={{width:"0%"}}></div>
                                    </div>
                                </div>
                                <div className=' d-flex align-items-center'>
                                    <span className='pe-lg-2 pe-1 text-black text-opacity-75 fs-9s'>3</span>
                                    <div className="progress w-100 placeholder col-12" role="progressbar" style={{height:"10px"}}>
                                        <div className="progress-bar bgcolor-primary rounded placeholder" style={{width:"0%"}}></div>
                                    </div>
                                </div>
                                <div className=' d-flex align-items-center'>
                                    <span className='pe-lg-2 pe-1 text-black text-opacity-75 fs-9s'>2</span>
                                    <div className="progress w-100 placeholder col-12" role="progressbar" style={{height:"10px"}}>
                                        <div className="progress-bar bgcolor-primary rounded placeholder" style={{width:"0%"}}></div>
                                    </div>
                                </div>
                                <div className=' d-flex align-items-center'>
                                    <span className='pe-lg-2 pe-1 text-black text-opacity-75 fs-9s'>1</span>
                                    <div className="progress w-100 placeholder col-12" role="progressbar" style={{height:"10px"}}>
                                        <div className="progress-bar bgcolor-primary rounded placeholder" style={{width:"0%"}}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default BiznessPlaceholder
