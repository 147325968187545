import iconW from '../assets/images/ICONE-BIZ-DARK.png'
import iconB from '../assets/images/ICONE-BIZ.png'
import logoB from '../assets/images/LOGO-BIZ.png'
import logoW from '../assets/images/LOGO-BIZ-WHITE.png'
import bgLogin from '../assets/images/bg-connexion.webp'
import profile_ from '../assets/images/user.webp'


// logo
export const icon_black = iconB
export const icon_white = iconW
export const logo_black = logoB
export const logo_white = logoW

//Bg
export const bg_login = bgLogin
export const profile = profile_