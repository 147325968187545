import React from 'react'
import { NavLink } from 'react-router-dom'

const CustomButtons = ({reload, link, icon_left, text, icon_rigth}) => {
    return (
        <NavLink reloadDocument={reload} to={link} className=" d-flex justify-content-center align-items-center px-3 py-2 bgcolor-primary rounded-3 text-white">
            {icon_left && <i className={ 'me-2 fs-5 ' + (icon_left)}></i>}
            <span className=' color-secondary text-white mt-1 fw-medium fs-6'> {text} </span>
            {icon_rigth && <i className={ 'ms-2 ' +(icon_rigth)}></i>}
        </NavLink>
    )
}

export default CustomButtons
