import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import biz from "../../api/axiosConfig";
import { unLikeBussinessUrl } from "../../api/enpoint";


//Action
export const unLikeBussiness = createAsyncThunk('unLikeBussiness', async (id)=>{
    const resp = await biz.delete(unLikeBussinessUrl+id)
    return resp.data;
})


const unLikeBussinessSlice = createSlice({
    name:'unLikeBussiness',
    initialState:{
        loadUnLikeB: false,
        unLikeB: null,
        errUnLikeB: null,
    },
    extraReducers: (builder) =>{
        builder
        .addCase(unLikeBussiness.pending, (state, action) =>{
            state.loadUnLikeB = true
            state.unLikeB = null
            state.errUnLikeB = null
        })
        .addCase(unLikeBussiness.fulfilled, (state, action) =>{
            state.loadUnLikeB = false
            state.unLikeB = action.payload
            state.errUnLikeB = null
        })
        .addCase(unLikeBussiness.rejected, (state, action) =>{
            state.loadUnLikeB = false
            if (action.error.message === "Request failed with status code 401") {
                state.errUnLikeB = 'Access Denied'
            } else {
                state.errUnLikeB = action.error.message
            }
        })
    }
})

export default unLikeBussinessSlice.reducer;