export const baseURL = 'https://api-biz.gastrolink-resto.com'

// User
export const loginUserUrl = "api/user/login"
export const registerUserUrl = "api/user/register"
export const getUserUrl = "api/user/get"
export const updateUserUrl = "api/user/update/"
export const checkUserPasswordUrl = "api/user/check-password"
export const updateUserPasswordUrl = "api/user/update-password/"
export const deleteUserAccountUrl = "api/user/delete/"



// Bussiness
export const getBussinessesUrl = "api/bussiness/get-by-user"
export const getOneBussinessUrl = "api/bussiness/get/"
export const createBussinessUrl = "api/bussiness/add"
export const updateBussinessUrl = "api/bussiness/update/"
export const deleteBussinessUrl = "api/bussiness/delete/"



// Domain
export const getDomainsURL = "api/domain/get-all"



// Product 
export const getProductsUrl = "api/product/get-all"
export const getProductsByBussinessUrl = "api/product/bussiness/"
export const getOneProductUrl = "api/product/get/"
export const createProductUrl = "api/product/add"
export const updateProductUrl = "api/product/update/"
export const deleteProductUrl = "api/product/delete/"



// Comment
export const postComment = "api/comment/add/"
export const updateComment = "api/comment/update/"
export const deleteComment = "api/comment/remove/"
export const getCommentByBussiness = "api/comment/get-by-bussiness/"



// Favorite
export const likeBussinessUrl = "api/favorite/add/"
export const unLikeBussinessUrl = "api/favorite/remove/"
export const verifyLikedBussinessUrl = "api/favorite/get-by-bussiness/"
export const likedBussinessByUserUrl = "api/favorite/get-by-user/"