import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchBussinesses } from '../redux/slices/bussinessGetAll'
import ErrorRelod from '../components/ErrorRelod'
import LargeBiznessCardPlaceholder from '../components/LargeBiznessCardPlaceholder'
import LargeBiznessCard from '../components/LargeBiznessCard'
import Modal from 'react-responsive-modal'
import { ToastContainer, toast } from 'react-toastify'
import { PhoneInput } from 'react-international-phone'
import biz from '../api/axiosConfig'
import { baseURL, createBussinessUrl } from '../api/enpoint'
import { fetchDomains } from '../redux/slices/domainsGet'
import Select from 'react-select';
import RedStar from '../components/RedStar'

const BuznessList = () => {

    const dispatch = useDispatch()
    const {domains} = useSelector(state => state.domains)

    const[data, setData]=useState([{}])
    useEffect(()=>{
        dispatch(fetchDomains())
    },[dispatch])

    useEffect(()=>{
        if (domains) {
            domains.map((elt) =>( data.push({label: elt.label, value: elt.id})))
            setData(data)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[domains])

    const { bussinesses, loadBussinesses, errGetBussinesses } = useSelector(state => state.bussinesses)
    const { user } = useSelector(state => state.user)

    const[add, setAdd] = useState(false)

    const[name, setName] = useState('')
    const[description, setDescription] = useState('')
    const[phone, setPhone] = useState('')
    const[email, setEmail] = useState('')
    const[photo, setPhoto] = useState()
    const[icon, setIcon] = useState()
    const[creation, setCreation] = useState('')
    const[location, setLocation] = useState('Douala')
    const[website, setWebsite] = useState('')
    const[facebook, setFacebook] = useState('')
    const[instagram, setInstagram] = useState('')
    const[linkedin, setLinkedin] = useState('')
    const[twitter, setTwitter] = useState('')
    const[domain, setDomain] = useState('')
    

    useEffect(()=>{
        dispatch(fetchBussinesses())
    },[dispatch])



    const toastload = useRef(null)
    const handleLog = (e) =>{
        e.preventDefault()
        if(name && email && phone && location){
            toastload.current = toast.loading("Veuillez patienter...",{position: toast.POSITION?.TOP_LEFT})
            const formData = new FormData()

            formData.append('icon', icon)
            formData.append('photo', photo)
            formData.append('name', name)
            formData.append('domain_id', domain?.value)
            formData.append('email', email)
            formData.append('telephone', phone)
            formData.append('creation', creation)
            formData.append('website', website)
            formData.append('facebook', facebook)
            formData.append('instagram', instagram)
            formData.append('twitter', twitter)
            formData.append('linkedin', linkedin)
            formData.append('description', description)
            formData.append('location', location)
            formData.append('created_by ', user.id)

            biz.post(createBussinessUrl, formData)
            .then(res =>{
                toast.update(toastload.current, {render: <span>Vous êtes connectés<br/> Bienvenu {res.data.name}</span>,type: "success",isLoading: false, hideProgressBar: false})
                dispatch(fetchBussinesses())
                window.location.reload()
            })
            .catch(err =>{
                toast.update(toastload.current, {render: err.response.data.email ?"email déjà existant ! Se connecter ?":err.response.data.pass?"Mot de passe incorrect":err.response.data.data ?"Remplir tout les champs obligatoires":"Une erreur c'est produite", type: "error", isLoading: false, hideProgressBar: false, closeOnClick: true})
                console.log('err',err.response.data)
            })
        }else{
            toastload.current = toast.error("Remplir tout les champs", {position: toast.POSITION?.TOP_LEFT, isLoading: false, autoClose: false,hideProgressBar: false, closeOnClick: true})
        }
    }



    return (
        <div>
            {
                loadBussinesses?
                    <div className=' row justify-content-start align-items-center'>
                        {
                            [1,2,3,4,5].map((elt,index) =>(
                                <div key={index+elt} className=' col-lg-4 col-md-6 col-12'>
                                    <LargeBiznessCardPlaceholder/>
                                </div>
                            ))
                        }
                    </div>
                :
                errGetBussinesses?
                    <ErrorRelod onclick={()=>dispatch(fetchBussinesses())} />
                :
                bussinesses?.length  <= 0?
                    <div className=' d-flex justify-content-center align-items-center flex-column' style={{minHeight:"20rem"}}>
                        <span className=' d-block fst-italic mb-4 fs-5'>Aucun Biz'ness trouvé</span>
                        <div role='button' onClick={()=>setAdd(true)} className=" d-flex justify-content-center align-items-center px-3 py-2 bgcolor-primary rounded-3 text-white">
                            <i className='me-2 fs-5 fa fa-add'></i>
                            <span className=' color-secondary text-white mt-1 fw-medium fs-6'> Créer un Biz'ness </span>
                        </div>
                    </div>
                :bussinesses?
                    <div className=' row justify-content-start align-items-start'>
                        <div className=' col-auto p-3'>
                            <div className=' rounded-3 d-flex justify-content-center align-items-center bg-body-secondary p-3 mx-auto' role='button' style={{width:'9rem',height:'9rem'}}  onClick={()=>setAdd(true)}>
                                <i className=' fa fa-plus fa-6x text-black-50'></i>
                            </div>
                        </div>
                        {
                            bussinesses.map((elt,index) =>(
                                <div key={index} className=' col-lg-4 col-md-6 col-12'>
                                    <LargeBiznessCard cover={baseURL+elt.photo} domain={elt.domain.label} location={elt.location} logo={baseURL+elt.icon} name={elt.name} rating={4} bizId={elt.id} creatorId={elt.created_by} />
                                </div>
                            ))
                        }
                    </div>
                :null
            }


            <Modal center={true} open={add} onClose={()=>setAdd(false)} >
                <div className=' p-md-4 p-1'>
                    <h4 className=' fs-3 fw-bold px-md-4 px-2'>Ajoutez votre Biz'ness</h4>
                    <div className=' bg-white px-3 py-4 text-center w-100 my-3' style={{maxWidth:'900px'}}>
                        <div className=' mx-auto'>

                            <form>
                                <div className=' d-flex align-items-center justify-content-between flex-wrap'>
                                    <div className='inputFile mb-2'>
                                        <span className=' text-black-50 fs-6 fw-medium'>Logo <RedStar/></span>
                                        <label role='button' htmlFor='icon' className='mt-2 d-flex flex-column justify-content-center align-items-center bg-body-secondary rounded-circle mx-auto overflow-hidden' style={{width:"5rem",height:"5rem"}}>
                                            {!icon?<i className=" ri-camera-line ri-3x opacity-50"></i>:<img alt='' src={URL.createObjectURL(icon)} className='object-fit-cover w-100 h-100' />}
                                        </label>
                                        <input name='icon' id='icon' onChange={(e)=>{setIcon(e.target.files[0])}} type='file' accept='image/*' max-file-size="1331200" className='w-100 d-none'/>
                                    </div>
                                    <div className='inputFile mb-2'>
                                        <span className=' text-black-50 fs-6 fw-medium'>Photo de couverture</span>
                                        <label role='button' htmlFor='photo' className='mt-2 d-flex flex-column justify-content-center align-items-center bg-body-secondary rounded-4 mx-auto overflow-hidden' style={{width:"8rem",height:"7rem"}}>
                                            {!photo?<i className=" ri-camera-line ri-3x opacity-50"></i>:<img alt='' src={URL.createObjectURL(photo)} className='object-fit-cover w-100 h-100' />}
                                        </label>
                                        <input name='photo' id='photo' onChange={(e)=>{setPhoto(e.target.files[0])}} type='file' accept='image/*' max-file-size="1331200" className='w-100 d-none'/>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-center align-items-center pb-1 border-bottom border-1 border-black'>
                                    <label htmlFor='name'  className='px-2 py-1 bgcolor-primary d-flex justify-content-center align-items-center ms-2'>
                                        <i className='ri-user-3-line text-white'></i><RedStar/>
                                    </label>
                                    <input type='text' value={name} onChange={(e)=>setName(e.target.value)} id='name' placeholder="Raison sociale" className='form-control border-0'/>
                                </div>
                                <div className='d-flex justify-content-center align-items-center pb-1 border-bottom border-1 border-black mt-4'>
                                    <label htmlFor='domain'  className='px-2 py-1 bgcolor-primary d-flex justify-content-center align-items-center ms-2'>
                                        <i className=' ri-building-line text-white'></i><RedStar/>
                                    </label>
                                    <Select
                                        value={domain}
                                        placeholder="Secteur"
                                        classNamePrefix =" border-0 "
                                        className=' border-0 w-100 text-start'
                                        onChange={setDomain}
                                        options={data}
                                    />
                                </div>
                                <div className='d-flex justify-content-center align-items-center pb-1 border-bottom border-1 border-black mt-4'>
                                    <label htmlFor='email'  className='px-2 py-1 bgcolor-primary d-flex justify-content-center align-items-center ms-2'>
                                        <i className=' ri-mail-line text-white'></i><RedStar/>
                                    </label>
                                    <input type='email' value={email} onChange={(e)=>setEmail(e.target.value)} id='email' placeholder="Adresse email" className='form-control border-0'/>
                                </div>
                                <div className='d-flex justify-content-center align-items-center pb-1 border-bottom border-1 border-black mt-4'>
                                    <label htmlFor='phone'  className='px-2 py-1 bgcolor-primary d-flex justify-content-center align-items-center ms-2'>
                                        <i className=' ri-phone-line text-white'></i><RedStar/>
                                    </label>
                                    <PhoneInput
                                        className=' w-100 border-0'
                                        defaultCountry="cm"
                                        value={phone}
                                        onChange={(phone) => setPhone(phone)}
                                    />
                                </div>
                                <div className='d-flex justify-content-center align-items-center pb-1 border-bottom border-1 border-black mt-4'>
                                    <label htmlFor='creation'  className='px-2 py-1 bgcolor-primary d-flex justify-content-center align-items-center ms-2'>
                                        <i className='ri-calendar-event-line text-white'></i><RedStar/>
                                    </label>
                                    <input type='date' value={creation} onChange={(e)=>setCreation(e.target.value)} id='creation' placeholder="Date de naissance" className='form-control border-0'/>
                                </div>
                                <div className='d-flex justify-content-center align-items-center pb-1 border-bottom border-1 border-black mt-4'>
                                    <label htmlFor='website'  className='px-2 py-1 bgcolor-primary d-flex justify-content-center align-items-center ms-2'>
                                        <i className='ri-global-line text-white'></i>
                                    </label>
                                    <input type='url' value={website} onChange={(e)=>setWebsite(e.target.value)} id='website' placeholder="Lien vers le website" className='form-control border-0'/>
                                </div>
                                <div className='d-flex justify-content-center align-items-center pb-1 border-bottom border-1 border-black mt-4'>
                                    <label htmlFor='facebook'  className='px-2 py-1 bgcolor-primary d-flex justify-content-center align-items-center ms-2'>
                                        <i className='ri-facebook-fill text-white'></i>
                                    </label>
                                    <input type='url' value={facebook} onChange={(e)=>setFacebook(e.target.value)} id='facebook' placeholder="Lien de la page facebook" className='form-control border-0'/>
                                </div>
                                <div className='d-flex justify-content-center align-items-center pb-1 border-bottom border-1 border-black mt-4'>
                                    <label htmlFor='instagram'  className='px-2 py-1 bgcolor-primary d-flex justify-content-center align-items-center ms-2'>
                                        <i className='ri-instagram-line text-white'></i>
                                    </label>
                                    <input type='url' value={instagram} onChange={(e)=>setInstagram(e.target.value)} id='instagram' placeholder="Lien de la page instagram" className='form-control border-0'/>
                                </div>
                                <div className='d-flex justify-content-center align-items-center pb-1 border-bottom border-1 border-black mt-4'>
                                    <label htmlFor='twitter'  className='px-2 py-1 bgcolor-primary d-flex justify-content-center align-items-center ms-2'>
                                        <i className='ri-twitter-x-line text-white'></i>
                                    </label>
                                    <input type='url' value={twitter} onChange={(e)=>setTwitter(e.target.value)} id='twitter' placeholder="Lien de la page twitter" className='form-control border-0'/>
                                </div>
                                <div className='d-flex justify-content-center align-items-center pb-1 border-bottom border-1 border-black mt-4'>
                                    <label htmlFor='linkedin'  className='px-2 py-1 bgcolor-primary d-flex justify-content-center align-items-center ms-2'>
                                        <i className='ri-linkedin-box-fill text-white'></i>
                                    </label>
                                    <input type='url' value={linkedin} onChange={(e)=>setLinkedin(e.target.value)} id='linkedin' placeholder="Lien de la page linkedin" className='form-control border-0'/>
                                </div>
                                <div className='d-flex justify-content-center align-items-start pb-1 border-bottom border-1 border-black mt-4'>
                                    <label htmlFor='description' className='px-2 py-1 bgcolor-primary d-flex justify-content-center align-items-center ms-2'>
                                        <i className=' ri-message-2-line text-white'></i><RedStar/>
                                    </label>
                                    <textarea value={description} onChange={(e)=>setDescription(e.target.value)} id='description' placeholder="Bio, à propos de vous" className='form-control border-0' style={{minHeight:"6rem"}}></textarea>
                                </div>

                                <input type='submit' value={"Créer le Biz'ness"} onClick={handleLog} className=' mt-3 py-2 border-0 bgcolor-primary w-100 text-white fw-bold'/>
                            </form>
                        </div>
                    </div>
                </div>
            </Modal>


            <ToastContainer pauseOnFocusLoss={false} hideProgressBar={true}/>
        </div>
    )
}

export default BuznessList