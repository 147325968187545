import React, { useEffect } from 'react'

// Import styles
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle'
import './assets/vendors/fontawesome/css/all.min.css'
import './assets/vendors/remixicon/remixicon.css'
import "react-multi-carousel/lib/styles.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import 'react-toastify/dist/ReactToastify.css';
import 'react-international-phone/style.css';
import 'react-responsive-modal/styles.css';
import 'react-responsive-combo-box/dist/index.css'
import './assets/styles/style.css'


import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { fetchUser } from './redux/slices/userGet'
import { PrimeReactProvider } from 'primereact/api';
import Header from './components/Header'
import Home from './views/Home'
import Login from './views/Login'
import Account from './views/Account'
import Bizness from './views/Bizness'

function App() {

  const dispatch = useDispatch()

  useEffect(()=>{
    dispatch(fetchUser())
  },[dispatch])


  return (
    <BrowserRouter>
    {
      <PrimeReactProvider>
        <Header/>
        <Routes>
          <Route path='/'>
            <Route index element={<Home/>} />
            <Route path='login' element={<Login/>} />
            <Route path='bizness/:bizId' element={<Bizness/>} />
            <Route path='account/:part' element={<Account/>} />
          </Route>
        </Routes>
      </PrimeReactProvider>
    }
    </BrowserRouter>
  );
}

export default App;
