import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "./slices/userLogin";
import getUserReducer from "./slices/userGet";
import getDomainsReducer from "./slices/domainsGet";
import getBussinessesReducer from "./slices/bussinessGetAll";
import getBussinessReducer from "./slices/bussinessGetOne";
import likeBussinessReducer from "./slices/likeBussiness";
import unLikeBussinessReducer from "./slices/unLikeBussiness";
import getBussinessFavoriteReducer from "./slices/likedBussiness";
import getUserFavoriteReducer from "./slices/likedUser";



export const store = configureStore(({
    reducer:{
        login: loginReducer,
        user: getUserReducer,

        bussinesses: getBussinessesReducer,
        bussiness: getBussinessReducer,

        domains: getDomainsReducer,


        likeB: likeBussinessReducer,
        unLikeB: unLikeBussinessReducer,
        favorite: getBussinessFavoriteReducer,
        favoriteU: getUserFavoriteReducer,
    }
}))