import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import biz from "../../api/axiosConfig";
import { getOneBussinessUrl } from "../../api/enpoint";


//Action
export const fetchBussiness = createAsyncThunk('fetchBussiness', async (data)=>{
    const resp = await biz.get(getOneBussinessUrl+data)
    return resp.data;
})


const getBussinessSlice = createSlice({
    name:'fetchBussiness',
    initialState:{
        loadBussiness: false,
        bussiness: null,
        errGetBussiness: null,
    },
    extraReducers: (builder) =>{
        builder
        .addCase(fetchBussiness.pending, (state, action) =>{
            state.loadBussiness = true
            state.bussiness = null
            state.errGetBussiness = null
        })
        .addCase(fetchBussiness.fulfilled, (state, action) =>{
            state.loadBussiness = false
            state.bussiness = action.payload
            state.errGetBussiness = null
        })
        .addCase(fetchBussiness.rejected, (state, action) =>{
            state.loadBussiness = false
            if (action.error.message === "Request failed with status code 401") {
                state.errGetBussiness = 'Access Denied'
            } else {
                state.errGetBussiness = action.error.message
            }
        })
    }
})

export default getBussinessSlice.reducer;